import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  MouseEvent,
} from 'react';
import { useParams } from 'react-router-dom';
import { ITemplateItem } from '../../types/templateItem.types';
import { ILink, SceneHandle } from '../../types/scene.types';
import { getProjectAction } from '../../actions/projects';
import { useAppDispatch } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';

import ProjectAside from '../../components/ProjectAside';

import styles from './Project.module.scss';
import ProjectHeader from '../../components/ProjectHeader';
import { getSceneAction } from '../../actions/scene';
import { getTemplateItemsAction } from '../../actions/templateItems';
import Scene from '../../components/Scene';
import { SET_ITEM_MODALE } from '../../actions/actions';
import { getProjectGroupAction } from '../../actions/group';

function Project() {
  const dispatch: DispatchType = useAppDispatch();
  const params = useParams<{ projectId: string, sceneId: string }>();
  const sceneRef = useRef<SceneHandle| null>(null);
  const [dragItem, setDragItem] = useState<ITemplateItem | null>(null);

  const getProject = useCallback(async () => {
    if (params.projectId) {
      await getProjectGroupAction(dispatch, params.projectId);
      await getProjectAction(dispatch, params.projectId);
    }
    if (params.sceneId) {
      await getSceneAction(dispatch, params.sceneId);
    }
    getTemplateItemsAction(dispatch);
  }, [dispatch, params.projectId, params.sceneId]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  function handleDragStart(item: ITemplateItem) {
    if (item) setDragItem(item);
  }

  function handleDrop(e: MouseEvent) {
    if (dragItem) sceneRef.current?.dragThenAdd({ x: e.pageX - 250, y: e.pageY }, dragItem);
    setDragItem(null);
  }

  async function handleClickAddItem(e: MouseEvent, item: ITemplateItem) {
    const res : any = await sceneRef.current?.dragThenAdd({ x: (sceneRef.current.size.width - 250) / 2, y: sceneRef.current.size.height / 2 }, item);
    console.log(res);
    if (res) {
      dispatch({
        type: SET_ITEM_MODALE,
        payload: res._id,
      });
    }
    setDragItem(null);
  }

  function handleDrag(e: MouseEvent) {
    sceneRef.current?.hightLightClosest({ x: e.pageX - 250, y: e.pageY });
  }

  return (
    <div className={styles.container}>
      <ProjectAside
        onDragItemStart={(item: ITemplateItem) => handleDragStart(item)}
        onClickAdd={(e: MouseEvent, item: ITemplateItem) => handleClickAddItem(e, item)}
        onDragItem={(e: MouseEvent) => handleDrag(e)}
        updateItem={(item : any, label: boolean) => sceneRef?.current?.drawTile({ ...item }, label)}
        setGroupModale={(groupId: string) => sceneRef?.current?.setGroupModale(groupId)}
        setItemModale={(itemId: string) => sceneRef?.current?.setItemModale(itemId)}
        setLinkModale={(link: ILink) => sceneRef?.current?.setLinkModale(link)}
      />
      <ProjectHeader setActiveView={(value: string) => sceneRef?.current?.setActiveView(value)} exportScene={() => sceneRef?.current?.exportScene()} />
      <div
        className={styles.sceneContainer}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => e.preventDefault()}
      >
        <Scene key={params.sceneId} ref={sceneRef} />
      </div>
    </div>
  );
}

export default Project;
