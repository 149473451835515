import React, { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { CgArrowLongLeft, CgArrowLongRight, CgArrowsHAlt } from 'react-icons/cg';
import { AiOutlineDelete } from 'react-icons/ai';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { SET_LINK_MODALE } from '../../actions/actions';
// import { deleteItemAction, putItemAction } from '../../actions/scene';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import {
  ErrorField, InputText, Textarea,
} from '../../lib/HooksFormFields';
import styles from './LinkModale.module.scss';
import { putItemAction } from '../../actions/scene';
import ColorChart from '../ColorChart/ColorChart';
import { Timer } from '../ProjectHeader';
import { hexToRgba } from '../../helpers/utils';
import Comments from '../Comments/Comments';
import { IContributor } from '../../types/projects.types';

function LinkIcon({ color }:{ color: string }) {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.15384 4.15381L22.8461 22.8461" stroke={color} strokeWidth="1.2" />
      <circle cx="4.15385" cy="4.15385" r="3.65385" fill={color} stroke={color} />
      <circle cx="22.8462" cy="22.8461" r="3.65385" fill={color} stroke={color} />
    </svg>
  );
}

function SelectStrength({ value, setValue }: {value: number, setValue: any }) {
  const options = [1, 3, 6];
  return (
    <div className={styles.select}>
      <label>Style</label>
      <div className={styles.options}>
        {options.map((opt) => (
          <button
            type="button"
            key={opt}
            className={`${value === opt ? styles.active : ''}`}
            onClick={() => setValue(opt)}
          >
            <div className={styles.bar} style={{ height: opt }} />
          </button>
        ))}
      </div>
    </div>
  );
}

function SelectDirection({ value, setValue }: {value: string, setValue: any }) {
  const options = ['target', 'all', 'source'];
  return (
    <div className={styles.select}>
      <label>Direction</label>
      <div className={styles.options}>
        {options.map((opt) => (
          <button
            type="button"
            key={opt}
            className={`${value === opt ? styles.active : ''}`}
            onClick={() => setValue(opt)}
          >
            {opt === 'target' && <CgArrowLongLeft />}
            {opt === 'all' && <CgArrowsHAlt />}
            {opt === 'source' && <CgArrowLongRight />}
          </button>
        ))}
      </div>
    </div>
  );
}

export default function LinkModale({
  updateItem, updateLink, deleteLink,
}: { updateItem: any, updateLink: any, deleteLink: any }) {
  const dispatch = useAppDispatch();
  const timerRef = useRef<Timer | null>();
  const { sceneReducer, authReducer } = useAppSelector((store) => store);
  const { project } = useAppSelector((state: any) => state.projectsReducer);
  const { user } = authReducer;
  const isMember = user?._id !== project?.owner._id && project?.contributors?.find((c :IContributor) => c.user._id === user?._id)?.role === 'Member';

  const { link } = sceneReducer.modale;
  const { itemsList, activeTool } = sceneReducer;
  const linksItem = itemsList?.find((d) => d._id === link?.source)?.links;
  const [comment, setComment] = useState('');

  const {
    watch,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
  } = useForm<FieldValues>();

  const requiredRule = { required: 'Ce champs est requis' };
  const linkData = watch();

  const watchData = useWatch({
    control,
  });

  async function onSubmit() {
    const links = [...linksItem];
    const index = linksItem.findIndex((i: any) => i.target === link.target);
    links[index] = { ...linkData };
    // const data = { ...form };
    if (link.source) {
      const res = await putItemAction(dispatch, link.source, { links });
      const resItem = res.item;
      updateItem(resItem);
    }
    updateLink(link.sourceSlot, link.targetSlot, link.source, link.target, linkData, true);
    return null;
  }

  function close() {
    itemsList.forEach((it : any) => {
      updateItem(it, false);
    });
    dispatch({
      type: SET_LINK_MODALE,
      payload: null,
    });
  }

  async function handleDelete() {
    if (link) {
      const links = linksItem.filter((i: any) => i.target !== link.target);
      if (link.source) {
        await putItemAction(dispatch, link.source, { links: [...links] });
        const id = `${link.source}>${link.target}`;
        deleteLink(id);
      }
    }
    dispatch({
      type: SET_LINK_MODALE,
      payload: null,
    });
  }

  function handleSave() {
    timerRef.current = setTimeout(() => {
      handleSubmit(onSubmit)();
    }, 1000);
  }

  async function submitComment() {
    if (link.source && user?._id) {
      const commentData = {
        comment,
        author: user._id,
        date: new Date().toISOString(),
      };
      const links = [...linksItem];
      const index = linksItem.findIndex((i: any) => i.target === link.target);
      const comments = linkData.comments || [];
      links[index] = { ...linkData, comments: [commentData, ...comments] };
      const res = await putItemAction(dispatch, link.source, { links });
      const resItem = res.item;
      await updateItem(resItem, true);
    }
    return setComment('');
  }

  useEffect(() => {
    if (link?.linkData) {
      updateLink(link.sourceSlot, link.targetSlot, link.source, link.target, link.linkData, true);
      reset(link.linkData);
    }
  }, [link?.linkData]);

  useEffect(() => {
    if (linksItem) {
      const links = [...linksItem];
      const index = linksItem.findIndex((i: any) => i.target === link.target);
      reset(links[index]);
    }
  }, [linksItem]);

  useEffect(() => {
    if (isDirty) {
      if (timerRef?.current) {
        clearTimeout(timerRef.current);
      }
      handleSave();
    }
  }, [watchData, isDirty]);

  return (
    <div className={`${styles.container} ${link ? styles.active : ''} ${isMember ? styles.member : ''}`}>
      <button
        type="button"
        className={`${styles.close}`}
        onClick={() => close()}
      >
        <MdClose />
      </button>
      <div className={styles.content}>
        {activeTool !== 'comment'
          ? (
            <>
              <div className={styles.form}>
                <div className={styles['container-field']}>
                  <InputText
                    name="title"
                    control={control}
                    rules={requiredRule}
                    label="Nom"
                    placeholder="Nom..."
                  />
                  {errors?.name && <ErrorField message={errors.name.message} />}
                </div>
                <div className={styles['container-field']}>
                  <Textarea
                    name="description"
                    control={control}
                    rules={{ }}
                    label="Description"
                    placeholder="Description..."
                  />
                  {errors?.description && <ErrorField message={errors.description.message} />}
                </div>
                <ColorChart
                  name="color"
                  value={watch('color')}
                  setValue={(value: string) => setValue('color', value, {
                    shouldDirty: true,
                  })}
                  rules={requiredRule}
                  control={control}
                  error={errors?.color?.message}
                />
                <SelectStrength
                  value={watch('strength')}
                  setValue={(value: string) => setValue('strength', value, {
                    shouldDirty: true,
                  })}
                />
                <SelectDirection
                  value={watch('direction')}
                  setValue={(value: string) => setValue('direction', value, {
                    shouldDirty: true,
                  })}
                />
              </div>
              <div className={styles.submit}>
                <button
                  type="button"
                  onClick={() => handleDelete()}
                >
                  <AiOutlineDelete />
                </button>
              </div>

            </>
          )
          : (
            <>
              <div className={styles.infos}>
                <div className={styles.title}>
                  {linkData?.color
                  && (
                    <div
                      className={styles.templateItem}
                      style={{
                        border: `1px solid ${linkData.color}`,
                        backgroundColor: hexToRgba(linkData.color, 0.5),
                      }}
                    >
                      <LinkIcon color={linkData.color} />
                    </div>
                  )}
                  <h3>{linkData?.title}</h3>
                </div>
                {linkData?.description && <p>{linkData?.description}</p>}
              </div>
              <div className={styles.comment}>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Ajoute un commentaire..."
                />
                {comment
                  && (
                  <button
                    type="button"
                    onClick={() => submitComment()}
                  >
                    <span>Valider</span>
                  </button>
                  )}
                {linkData?.comments
                  && (
                  <p>
                    {linkData?.comments.length}
                    {' '}
                    Commentaire
                    {linkData?.comments.length !== 1 && 's'}
                  </p>
                  )}
              </div>
              <Comments
                comments={linkData?.comments}
              />
            </>
          )}
      </div>
    </div>
  );
}
