import React from 'react';
import avatar from '../../assets/images/avatar.png';
import { IComment } from '../../types/scene.types';
import styles from './Comments.module.scss';

export default function Comments({
  comments,
}:{
  comments: IComment[]
}) {
  return (
    <div className={styles.comments}>
      {comments?.map((c) => (
        <div key={c._id} className={styles.comment}>
          <div className={styles.header}>
            <div className={styles.profile}>
              <div className={styles.avatar}>
                <img src={c.author?.avatar?.path ? `${process.env.REACT_APP_API_URL}/${c.author?.avatar?.path}` : avatar} alt="bakalab-avatar" />
              </div>
              <p>
                {c.author?.profile?.firstName}
                {' '}
                {c.author?.profile?.lastName}
              </p>
            </div>
            <p>
              {c.date && new Intl.DateTimeFormat('fr', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(c.date))}
            </p>
          </div>
          <p>{c.comment}</p>
        </div>
      ))}
    </div>
  );
}
