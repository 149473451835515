import React, { useEffect, useRef, useState } from 'react';
import { FiDownload, FiShare2 } from 'react-icons/fi';
import { SET_ACTIVE_TOOL } from '../../actions/actions';
import { putProjectAction } from '../../actions/projects';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import ModalStandalone, { ModaleHandle } from '../../lib/ModalComponents/ModalStandalone';
import { DispatchType } from '../../reducers';
import { IContributor } from '../../types/projects.types';
import SceneTools from '../SceneTools/SceneTools';
import UserModale from '../UserModale';
import styles from './ProjectHeader.module.scss';

export type Timer = ReturnType<typeof setTimeout>

// eslint-disable-next-line no-unused-vars
function ProjectHeader({ setActiveView, exportScene } : { setActiveView: (tool: any) => void, exportScene: () => void }) {
  const { project, isLoading } = useAppSelector((state: any) => state.projectsReducer);
  const { user } = useAppSelector((state: any) => state.authReducer);
  const isMember = user._id !== project?.owner?._id && project?.contributors?.find((c :IContributor) => c.user._id === user._id)?.role === 'Member';

  const dispatch: DispatchType = useAppDispatch();
  const [title, setTitle] = useState('');
  const timerRef = useRef<Timer | null>();
  const modaleRef = useRef<ModaleHandle>(null);

  function handleChangeTitle() {
    timerRef.current = setTimeout(() => {
      putProjectAction(dispatch, project._id, { title });
    }, 1000);
  }

  useEffect(() => {
    if (project?.title) {
      setTitle(project?.title);
    }
  }, [project?.title]);

  useEffect(() => {
    if (project?._id && !isLoading && title && project?.title !== title) {
      if (timerRef?.current) {
        clearTimeout(timerRef.current);
      }
      handleChangeTitle();
    }
  }, [title]);

  useEffect(() => {
    if (isMember) {
      dispatch({
        type: SET_ACTIVE_TOOL,
        payload: 'comment',
      });
    }
  }, [isMember]);

  return (
    <header className={styles.container}>
      <div className={styles.title}>
        <p>
          Dossier /
        </p>
        {!isMember
          ? (
            <input
              type="text"
              placeholder="Nom du projet"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          )
          : (
            <p>{title}</p>
          )}
        {isLoading
          && <div className={styles.loader} />}
      </div>
      {!isMember && <SceneTools setActiveView={setActiveView} />}
      {!isMember && (
        <>
          <div className={styles.actions}>
            <button
              className={`${styles.btn} ${styles['primary-icon']}`}
              onClick={() => exportScene()}
            >
              <FiDownload />
            </button>
            <button
              className={`${styles.btn} ${styles['primary-icon']}`}
              onClick={() => modaleRef.current && modaleRef.current.toggle()}
            >
              <FiShare2 />
            </button>
          </div>
          <ModalStandalone
            ref={modaleRef}
            domNode="modale"
          >
            <UserModale />
          </ModalStandalone>
        </>
      )}

    </header>
  );
}

export default ProjectHeader;
