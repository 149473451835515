import React, { useEffect } from 'react';
import { FieldError } from 'react-hook-form';
import styles from './error-field.module.scss';

interface IErrorField {
  message?: string | FieldError | any,
}

function ErrorField({ message = '' }: IErrorField): JSX.Element {
  useEffect(() => {
    if (typeof message !== 'string') console.log(message);
  }, [message]);
  return (
    <div className={styles['errors-fields']}>
      <p>{(typeof message === 'string') && message}</p>
    </div>
  );
}

export default ErrorField;
