import {
  deleteData, getData, postData, putData,
} from '.';
import { DispatchType } from '../reducers';
import { IGroup } from '../types/group.types';
import {
  DELETE_GROUP,
  ERROR_PROJECT, GET_PROJECT_GROUPS, POST_GROUP, PUT_GROUP,
} from './actions';

export const getProjectGroupAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/group/project/${id}`;
  let groups;
  const promise = await getData(ERROR_PROJECT, url, dispatch, true).then((response: any) => {
    groups = response.data.groups;
  });
  dispatch({
    type: GET_PROJECT_GROUPS,
    payload: groups,
  });
  return promise;
};

export const postGroupAction = async (dispatch: DispatchType, data: IGroup): Promise<any> => {
  const url = '/group';
  let group: IGroup | null = null;
  const promise = await postData(ERROR_PROJECT, url, dispatch, data, true);
  if (promise.data.group) {
    group = promise.data.group;
    dispatch({
      type: POST_GROUP,
      payload: group,
    });
  }
  return promise;
};

export const putGroupAction = async (dispatch: DispatchType, id: string, data: IGroup): Promise<any> => {
  const url = `/group/${id}`;
  let group: IGroup | null = null;
  const promise : any = await putData(PUT_GROUP, ERROR_PROJECT, url, dispatch, data, true);
  if (promise.group) {
    group = promise.group;
    dispatch({
      type: PUT_GROUP,
      payload: group,
    });
  }
  return promise;
};

export const deleteGroupAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/group/${id}`;

  const promise = await deleteData(ERROR_PROJECT, url, dispatch, true);
  if (promise.message) {
    dispatch({
      type: DELETE_GROUP,
      payload: id,
    });
  }
  return id;
};
