export function sqr(a: number): number {
  return a * a;
}

// GET DISTANCE BETWEEN TWO POINTS
export function distance(posx1: number, posy1: number, posx2: number, posy2: number): number {
  return Math.sqrt(sqr(posy2 - posy1) + sqr(posx2 - posx1));
}

// GET POSITION OF POINT ON LINE
export function pntOnLine(line: Array<number[]>, targetDistance: number): { x: number, y: number } {
  const t = targetDistance / distance(line[0][0], line[0][1], line[1][0], line[1][1]);
  const xt = (1 - t) * line[0][0] + (t * line[1][0]);
  const yt = (1 - t) * line[0][1] + (t * line[1][1]);
  return { x: xt, y: yt };
}

// IN RAD
export function angle(posx1: number, posy1: number, posx2: number, posy2: number): number {
  return Math.atan2(posy2 - posy1, posx2 - posx1);
}
