import * as React from 'react';

function ArrowSvg({ color }:{ color: string }) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7.18625 15.875L9.3825 9.40875L15.875 7.18625L1 1Z"
        stroke={color}
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ArrowSvg;
