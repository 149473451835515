import React, { KeyboardEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  InputEmail,
  InputPassword,
  ErrorField,
  InputText,
} from '../../../lib/HooksFormFields';

import styles from '../AuthForm.module.scss';
import { IRegisterFormValues } from '../../../types/auth.types';

interface IRegisterForm {
  errorMessage?: string | null,
  // eslint-disable-next-line no-unused-vars
  submit(data: any): any,
}

function RegisterForm({
  errorMessage,
  submit,
}: IRegisterForm) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IRegisterFormValues>();
  const requiredRule = { required: 'Ce champs est requis' };

  function onSubmit(data: IRegisterFormValues) {
    submit(data);
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <form autoComplete="off" className={styles.container}>
      <div className={styles.link}>
        Vous avez déjà un compte ?
        {' '}
        <NavLink to="/login">Connectez-vous !</NavLink>
      </div>

      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={`${styles['container-field']} ${watch('profile.lastName') ? styles['has-value'] : ''}`}>
          <InputText
            name="profile.lastName"
            control={control}
            rules={requiredRule}
            label="Nom"
          />
          {errors?.profile?.lastName && <ErrorField message={errors.profile?.lastName.message} />}
        </div>

        <div className={`${styles['container-field']} ${watch('profile.firstName') ? styles['has-value'] : ''}`}>
          <InputText
            name="profile.firstName"
            control={control}
            rules={requiredRule}
            label="Prénom"
          />
          {errors?.profile?.firstName && <ErrorField message={errors.profile?.firstName.message} />}
        </div>

        <div className={`${styles['container-field']} ${watch('email') ? styles['has-value'] : ''}`}>
          <InputEmail
            name="email"
            control={control}
            required
            label="Mail"
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        <div className={`${styles['container-field']} ${watch('password') ? styles['has-value'] : ''}`}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Mot de passe"
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
        <div className={`${styles['container-field']} ${watch('passwordConfirm') ? styles['has-value'] : ''}`}>
          <InputPassword
            name="passwordConfirm"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Mot de passe"
          />
          {errors.passwordConfirm && <ErrorField message={errors.passwordConfirm.message} />}
        </div>
        {(!errors.email && !errors.password) && errorMessage
          && (
            <p className={styles['message-error']}>
              {errorMessage}
            </p>
          )}
      </div>
      <div className={styles.btn}>
        <button
          className={styles.primary}
          onClick={handleSubmit(onSubmit)}
        >
          Me connecter
        </button>
      </div>
    </form>
  );
}

export default RegisterForm;
