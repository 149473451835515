import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { useAppSelector, useAppDispatch } from './hooks/useRedux';
import { getProfileAction, logout } from './actions/auth';

import SignIn from './pages/Auth/SignIn';
import Forgot from './pages/Auth/Forgot';
import Reset from './pages/Auth/Reset';
import Register from './pages/Auth/Register';

import Home from './pages/Home';
import Projects from './pages/Projects';
import Project from './pages/Project';
import Profile from './pages/Profile';
// import Lab from './pages/lab';
import Loader from './components/Loader';
import Toaster from './components/Toaster';
import EditProject from './pages/EditProject';

function Redirect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authReducer = useAppSelector((state: any) => state.authReducer);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch);
    else navigate('/login');
  }, []);

  useEffect(() => {
    if (authReducer.user) navigate('/home');
  }, [authReducer?.user]);

  return <Loader />;
}

function AppRoutes() {
  const authReducer = useAppSelector((state: any) => state.authReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (authReducer.error === "Vous n'êtes pas autoriser à accéder à ce contenu") {
      logout(dispatch);
      navigate('/login');
    }
  }, [authReducer?.error]);
  return (
    <>
      <Routes>
        <Route path="/" element={<Redirect />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/register/:projectId" element={<Register />} />
        <Route path="/reset-password/:token" element={<Reset />} />
        {/* <Route path="/lab" element={<Lab />} /> */}
        {authReducer.user && (
          <>
            <Route path="/home" element={<Home />}>
              <Route index element={<Projects />} />
              <Route path="/home/profile" element={<Profile />} />
              <Route path="/home/project/:projectId" element={<EditProject />} />
            </Route>
            <Route path="/project">
              <Route path="/project/:projectId" element={<Project />} />
              <Route path="/project/:projectId/:sceneId" element={<Project />} />
            </Route>
          </>
        )}
        <Route path="*" element={<Redirect />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default AppRoutes;
