import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DispatchType } from '../../../reducers';
import {
  forgotPassword,
  resetError,
} from '../../../actions/auth';
import { IForgotPassword } from '../../../types/auth.types';
import ForgotForm from '../../../components/AuthForm/ForgotForm';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import styles from '../Auth.module.scss';

function Forgot() {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useAppSelector((state: any) => state.authReducer);
  const [succeed, setSucceed] = useState(false);
  const successMessage = 'Un email contenant le lien pour modifié votre mot de passe vous a été envoyé';

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (authReducer.user?._id) navigate('/home');
  }, [
    authReducer.user,
  ]);

  function submit(data: IForgotPassword) {
    forgotPassword(dispatch, data).then(() => {
      setSucceed(true);
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.header}>
          <h2>Mot de passe oublié ?</h2>
        </div>
        <ForgotForm
          submit={(data) => submit(data)}
          errorMessage={authReducer.error}
          successMessage={succeed ? successMessage : ''}
        />
      </div>
    </div>
  );
}

export default Forgot;
