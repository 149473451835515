import * as React from 'react';

function LinkSvg({ color }:{ color: string }) {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.81226 2.81226L15.4672 15.4672"
        stroke={color}
        strokeWidth={1.47692}
      />
      <ellipse
        cx={2.81222}
        cy={2.81222}
        rx={2.81222}
        ry={2.81222}
        fill={color}
      />
      <circle cx={15.4672} cy={15.4672} r={2.81222} fill={color} />
    </svg>
  );
}
export default LinkSvg;
