import React, { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import { FieldValues, useForm, useWatch } from 'react-hook-form';

import { SET_ITEM_MODALE } from '../../actions/actions';
import { deleteItemAction, putItemAction } from '../../actions/scene';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import {
  ErrorField, InputText, StyledSelect, Textarea,
} from '../../lib/HooksFormFields';
import styles from './ItemModale.module.scss';
import ColorChart from '../ColorChart/ColorChart';
import SelectItem from '../SelectItem/SelectItem';
import { Timer } from '../ProjectHeader';
import { hexToRgba } from '../../helpers/utils';
import Comments from '../Comments/Comments';
import { IContributor } from '../../types/projects.types';
import { IGroup } from '../../types/group.types';

export default function ItemModale({ updateItem, deleteItem }: { updateItem: any, deleteItem: any }) {
  const dispatch = useAppDispatch();
  const timerRef = useRef<Timer | null>();
  const { sceneReducer, authReducer } = useAppSelector((store) => store);
  const { project } = useAppSelector((state: any) => state.projectsReducer);
  const { user } = authReducer;
  const isMember = user?._id !== project?.owner._id && project?.contributors?.find((c :IContributor) => c.user._id === user?._id)?.role === 'Member';
  const { item } = sceneReducer.modale;
  const { itemsList, activeTool, groups } = sceneReducer;
  const groupsList = groups.map((g: IGroup) => ({ value: g._id, label: g.title }));
  const emptyGroup = { value: null, label: 'Aucun groupe' };
  const groupsOptions = [emptyGroup, ...groupsList];
  const {
    watch,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
    handleSubmit,
  } = useForm<FieldValues>();

  const watchData = useWatch({
    control,
  });

  const [comment, setComment] = useState('');

  const requiredRule = { required: 'Ce champs est requis' };
  const foundItem = item?._id && itemsList.find((it) => it._id === item?._id);
  const form = watch();

  async function onSubmit() {
    const data = { ...form };
    data.group = data.group?.value || null;
    if (item?._id) {
      const res = await putItemAction(dispatch, item._id, data);
      const resItem = res.item;
      return updateItem(resItem, true);
    }
    return null;
  }
  function close() {
    itemsList.forEach((it : any) => {
      updateItem(it, false);
    });
    dispatch({
      type: SET_ITEM_MODALE,
      payload: null,
    });
  }

  async function handleDelete() {
    if (item?._id) {
      await deleteItemAction(dispatch, item._id);
      await deleteItem(item._id);
      dispatch({
        type: SET_ITEM_MODALE,
        payload: null,
      });
    }
  }

  function handleSave() {
    timerRef.current = setTimeout(() => {
      handleSubmit(onSubmit)();
    }, 1000);
  }

  useEffect(() => {
    if (foundItem && foundItem?._id) {
      const updatedItem = {
        ...foundItem,
        group: groupsOptions?.find((g: any) => g.value === foundItem?.group?._id) || emptyGroup,
      };
      reset(updatedItem);
      updateItem(foundItem, true);
    }
  }, [foundItem?._id]);

  useEffect(() => {
    if (isDirty) {
      if (timerRef?.current) {
        clearTimeout(timerRef.current);
      }
      handleSave();
    }
  }, [watchData, isDirty]);

  async function submitComment() {
    if (item?._id && user?._id) {
      const commentData = {
        comment,
        author: user._id,
        date: new Date().toISOString(),
      };
      const comments = foundItem.comments || [];
      const res = await putItemAction(dispatch, item._id, { comments: [commentData, ...comments] });
      const resItem = res.item;
      await updateItem(resItem, true);
    }
    return setComment('');
  }

  return (
    <div className={`${styles.container} ${foundItem ? styles.active : ''} ${isMember ? styles.member : ''}`}>
      <button
        type="button"
        className={`${styles.close} ${isDirty}`}
        onClick={() => close()}
      >
        <MdClose />
      </button>
      <div className={styles.content}>
        {activeTool !== 'comment'
          ? (
            <>
              <div className={styles.form}>
                <div className={styles['container-field']}>
                  <InputText
                    name="title"
                    control={control}
                    rules={requiredRule}
                    label="Nom"
                    placeholder="Nom..."
                  />
                  {errors?.name && <ErrorField message={errors.name.message} />}
                </div>
                <div className={styles['container-field']}>
                  <Textarea
                    name="description"
                    control={control}
                    rules={{ }}
                    label="Description"
                    placeholder="Description..."
                  />
                  {errors?.description && <ErrorField message={errors.description.message} />}
                </div>
                <ColorChart
                  name="color"
                  value={watch('color')}
                  setValue={(value: string) => setValue('color', value, {
                    shouldDirty: true,
                  })}
                  rules={requiredRule}
                  control={control}
                  error={errors?.color?.message}
                />
                {groupsOptions.length > 0
                  && (
                  <div className={`${styles['container-field']} ${styles.select}`}>
                    <StyledSelect
                      label="Groupe"
                      name="group"
                      rules={requiredRule}
                      control={control}
                      options={groupsOptions}
                    />
                  </div>
                  )}
                <SelectItem
                  label="Personnalisation"
                  value={watch('templateItem')?._id || watch('templateItem')}
                  setValue={(value: string) => setValue('templateItem', value, {
                    shouldDirty: true,
                  })}
                  color={watch('color') || '#FA5C4F'}
                  category={foundItem?.templateItem?.category}
                />
              </div>
              <div className={styles.submit}>
                <button
                  type="button"
                  onClick={() => handleDelete()}
                >
                  <AiOutlineDelete />
                </button>
              </div>
            </>
          )
          : (
            <>
              <div className={styles.infos}>
                <div className={styles.title}>
                  {foundItem?.templateItem
                  && (
                    <div
                      className={styles.templateItem}
                      style={{
                        border: `1px solid ${foundItem.color}`,
                        backgroundColor: hexToRgba(foundItem.color, 0.5),
                      }}
                    >
                      {foundItem?.templateItem?.picture && (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${foundItem.templateItem.picture.path}`}
                        alt={foundItem.templateItem.picture.name}
                      />
                      )}
                    </div>
                  )}
                  <h3>{foundItem?.title}</h3>
                </div>
                {foundItem?.description && <p>{foundItem?.description}</p>}
              </div>
              <div className={styles.comment}>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Ajoute un commentaire..."
                />
                {comment
                  && (
                  <button
                    type="button"
                    onClick={() => submitComment()}
                  >
                    <span>Valider</span>
                  </button>
                  )}
                {foundItem?.comments
                  && (
                  <p>
                    {foundItem?.comments.length}
                    {' '}
                    Commentaire
                    {foundItem?.comments.length !== 1 && 's'}
                  </p>
                  )}
              </div>
              <Comments
                comments={foundItem?.comments}
              />
            </>
          )}
      </div>
    </div>
  );
}
