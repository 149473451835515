import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { VscFile } from 'react-icons/vsc';
import { getProjectsAction, searchProject } from '../../actions/projects';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import { IProject } from '../../types/projects.types';
import styles from './HomeAside.module.scss';

function ProjectLink({ project } : { project: IProject }) {
  const url = project.scenes.length > 0
    ? `/project/${project._id}/${project.scenes[0]?._id}`
    : `/project/${project._id}`;
  return (
    <li>
      <NavLink to={url}>
        <VscFile size={20} />
        {project.title}
      </NavLink>
    </li>
  );
}

function HomeAside() {
  const dispatch: DispatchType = useAppDispatch();
  const { projectsList, search } = useAppSelector((state: any) => state.projectsReducer);
  const [projects, setProjects] = useState([]);

  const getProjects = useCallback(() => {
    getProjectsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (projectsList) {
      const lists = projectsList.filter((p: IProject) => {
        if (p.title.toLowerCase().includes(search.toLowerCase())) return p;
        return null;
      }).sort((a: IProject, b: IProject) => {
        const aDate = new Date(a.updatedAt).getTime();
        const bDate = new Date(b.updatedAt).getTime();
        return bDate - aDate;
      });
      setProjects(lists);
    }
  }, [projectsList, search]);

  return (
    <aside className={styles.container}>
      <NavLink to="/home" className={styles.logo}>Bakalab</NavLink>
      <div className={styles.search}>
        <FiSearch size={20} />
        <input
          type="text"
          placeholder="Recherche"
          value={search}
          onChange={(e) => searchProject(dispatch, e.target.value)}
        />
      </div>
      <div className={styles.project}>
        <NavLink to="/home">
          <h2>Projets</h2>
        </NavLink>
      </div>
      <ul>
        {projects?.map((p: IProject) => <ProjectLink key={p._id} project={p} />)}
      </ul>
    </aside>
  );
}

export default HomeAside;
