import React, {
  useState, useEffect, MouseEvent, useRef,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { VscAdd, VscEdit } from 'react-icons/vsc';
import { BiPlus } from 'react-icons/bi';
import { ITemplateItem } from '../../types/templateItem.types';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import styles from './LibAside.module.scss';
import ModalStandalone, { ModaleHandle } from '../../lib/ModalComponents/ModalStandalone';
import ItemModale from '../CreateItemModale';
import { TEMPLATE_ITEM_EDIT_MODALE } from '../../actions/actions';
import { IContributor } from '../../types/projects.types';

interface ILibAside {
  onDragItem: any,
  onDragItemStart: any,
  onClickAdd: any,
}

function LibAside({ onDragItem, onDragItemStart, onClickAdd }: ILibAside) {
  const dispatch = useAppDispatch();
  const { templateItemsReducer } = useAppSelector((state: any) => state);
  const { project } = useAppSelector((state: any) => state.projectsReducer);
  const { user } = useAppSelector((state: any) => state.authReducer);
  const isMember = user._id !== project?.owner?._id && project?.contributors?.find((c :IContributor) => c.user._id === user._id)?.role === 'Member';
  const { templateItems, isLoading } = templateItemsReducer;
  const [openedGroup, setOpenedGroup] = useState('actor');
  const modaleRef = useRef<ModaleHandle>(null);
  const [groupTemplateItems, setGroupTemplateItems] = useState({
    actor: [],
    board: [],
    object: [],
  });

  function handleEditItem(item: ITemplateItem | null = null) {
    if (modaleRef.current) {
      modaleRef.current.toggle();
      dispatch({
        type: TEMPLATE_ITEM_EDIT_MODALE,
        payload: item,
      });
    }
  }
  // GROUP TEMPLATE ITEMS BY CATEGORY
  useEffect(() => {
    const res = templateItems.reduce((group: any, item: ITemplateItem) => {
      const g = { ...group };
      (g[item.category] = g[item.category] || []).push(item);
      return g;
    }, {});
    setGroupTemplateItems(res);
  }, [templateItems]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {isLoading && (
          <p>Loading</p>
        )}
        {groupTemplateItems?.actor?.length > 0 && (
          <section>
            <div className={styles.header} onClick={() => setOpenedGroup((state) => (state === 'actor' ? '' : 'actor'))}>
              <h4>
                User&nbsp;
                <span>
                  (
                  {groupTemplateItems?.actor?.length}
                  )
                </span>
              </h4>
              {openedGroup === 'actor'
                ? <FiChevronUp />
                : <FiChevronDown />}
            </div>
            <div className={`${styles.containerList} ${openedGroup === 'actor' ? styles.opened : ''}`}>
              <ul>
                {groupTemplateItems?.actor.map((item: ITemplateItem) => (
                  <li key={`actor-${item._id}`}>
                    <div
                      className={styles.templateItem}
                      draggable="true"
                      onDrag={(e: MouseEvent) => onDragItem(e, item)}
                      onDragStart={() => onDragItemStart(item)}
                    >
                      {item.picture && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.picture.path}`}
                          alt={item.name}
                        />
                      )}
                    </div>
                    <div className={styles.actions}>
                      {!isMember
                      && (
                      <button
                        className={`${styles.btn} ${styles['primary-icon']}`}
                        onClick={(e) => onClickAdd(e, item)}
                      >
                        <VscAdd />
                      </button>
                      )}
                      {user.role === 'Admin'
                      && (
                      <button
                        className={`${styles.btn} ${styles['primary-icon']}`}
                        onClick={() => handleEditItem(item)}
                      >
                        <VscEdit />
                      </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}
        {groupTemplateItems?.board?.length > 0 && (
          <section>
            <div className={styles.header} onClick={() => setOpenedGroup((state) => (state === 'board' ? '' : 'board'))}>
              <h4>
                Plateforme&nbsp;
                <span>
                  (
                  {groupTemplateItems?.board?.length}
                  )
                </span>
              </h4>
              {openedGroup === 'board'
                ? <FiChevronUp />
                : <FiChevronDown />}
            </div>
            <div className={`${styles.containerList} ${openedGroup === 'board' ? styles.opened : ''}`}>
              <ul>
                {groupTemplateItems?.board.map((item: ITemplateItem) => (
                  <li key={`board-${item._id}`}>
                    <div
                      className={styles.templateItem}
                      draggable="true"
                      onDrag={(e: MouseEvent) => onDragItem(e)}
                      onDragStart={() => onDragItemStart(item)}
                    >
                      {item.picture && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.picture.path}`}
                          alt={item.name}
                        />
                      )}
                    </div>
                    {!isMember
                      && (
                      <button
                        className={`${styles.btn} ${styles['primary-icon']}`}
                        onClick={(e) => onClickAdd(e, item)}
                      >
                        <VscAdd />
                      </button>
                      )}
                    {user.role === 'Admin'
                    && (
                    <button
                      className={`${styles.btn} ${styles['primary-icon']}`}
                      onClick={() => handleEditItem(item)}
                    >
                      <VscEdit />
                    </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}
        {groupTemplateItems?.object?.length > 0 && (
          <section>
            <div className={styles.header} onClick={() => setOpenedGroup((state) => (state === 'object' ? '' : 'object'))}>
              <h4>
                Objet&nbsp;
                <span>
                  (
                  {groupTemplateItems?.object?.length}
                  )
                </span>
              </h4>
              {openedGroup === 'object'
                ? <FiChevronUp />
                : <FiChevronDown />}
            </div>
            <div className={`${styles.containerList} ${openedGroup === 'object' ? styles.opened : ''}`}>
              <ul>
                {groupTemplateItems?.object.map((item: ITemplateItem) => (
                  <li key={`object-${item._id}`}>
                    <div
                      className={styles.templateItem}
                      draggable="true"
                      onDrag={(e: MouseEvent) => onDragItem(e)}
                      onDragStart={() => onDragItemStart(item)}
                    >
                      {item.picture && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.picture.path}`}
                          alt={item.name}
                        />
                      )}
                    </div>
                    {!isMember
                      && (
                      <button
                        className={`${styles.btn} ${styles['primary-icon']}`}
                        onClick={(e) => onClickAdd(e, item)}
                      >
                        <VscAdd />
                      </button>
                      )}
                    {user.role === 'Admin'
                    && (
                    <button
                      className={`${styles.btn} ${styles['primary-icon']}`}
                      onClick={() => handleEditItem(item)}
                    >
                      <VscEdit />
                    </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}
      </div>
      {user.role === 'Admin'
      && (
        <>
          <button
            className={`${styles.add}`}
            onClick={() => handleEditItem()}
          >
            <span>Ajouter à la Bibliothèque</span>
            <BiPlus />
          </button>
          <ModalStandalone
            ref={modaleRef}
            domNode="modale"
          >
            <ItemModale />
          </ModalStandalone>
        </>
      )}
    </div>
  );
}

export default LibAside;
