import React from 'react';
import {
  Outlet,
} from 'react-router-dom';
import AuthProfile from '../../components/AuthProfile';
import HomeAside from '../../components/HomeAside';
import styles from './Home.module.scss';

function Home() {
  return (
    <div className={styles.container}>
      <AuthProfile />
      <HomeAside />
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default Home;
