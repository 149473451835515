import React, { useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { DispatchType } from '../../../reducers';
import {
  register,
  resetError,
} from '../../../actions/auth';
import { IRegisterFormValues } from '../../../types/auth.types';
import RegisterForm from '../../../components/AuthForm/RegisterForm';

import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import styles from '../Auth.module.scss';

function Register() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch: DispatchType = useAppDispatch();
  const location = useLocation();
  const { user, error } = useAppSelector((state: any) => state.authReducer);

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (user?._id) {
      navigate('login');
    }
  }, [user]);

  async function submit(data: IRegisterFormValues) {
    const obj = { ...data };
    if (params?.projectId) {
      obj.project = params?.projectId;
    }
    const res = await register(dispatch, obj);
    console.log(res);
    if (res) {
      navigate('login');
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.header}>
          <h2>Créer mon compte</h2>
        </div>
        <RegisterForm
          submit={(data) => submit(data)}
          errorMessage={error}
        />
      </div>
    </div>
  );
}

export default Register;
