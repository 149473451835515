import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DispatchType } from '../../../reducers';
import {
  signIn,
  resetError,
  getProfileAction,
} from '../../../actions/auth';
import { ISignIn } from '../../../types/auth.types';
import SignInForm from '../../../components/AuthForm/SignInForm';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import styles from '../Auth.module.scss';

function SignIn() {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useAppSelector((state: any) => state.authReducer);

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (authReducer.user?._id) {
      navigate('/home');
      getProfileAction(dispatch);
    }
  }, [
    authReducer.user,
  ]);

  function submit(data: ISignIn) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.header}>
          <h2>Connectez-vous !</h2>
        </div>
        <SignInForm
          submit={(data) => submit(data)}
          errorMessage={authReducer.error}
        />
      </div>
    </div>
  );
}

export default SignIn;
