import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { reducers } from './reducers';
import Routes from './Routes';
import useWindowSize from './helpers/useWindowSize';
import Mobile from './pages/Mobile';

function App() {
  const [width] = useWindowSize();
  return (
    <Provider store={reducers}>
      <div className="App">
        {width > 1024
          ? (
            <Router>
              <Routes />
            </Router>
          )
          : <Mobile />}
      </div>
    </Provider>
  );
}

export default App;
