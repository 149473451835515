import React, { useEffect } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { postTemplateItemsAction, putTemplateItemAction } from '../../actions/templateItems';
import { hexToRgba } from '../../helpers/utils';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import {
  ErrorField, InputColor, InputText, Textarea,
} from '../../lib/HooksFormFields';
import InputFile from '../../lib/InputFile';

import styles from './CreateItemModale.module.scss';

const itemOptions = [
  { value: 'actor', label: 'User' },
  { value: 'board', label: 'Platforme' },
  { value: 'object', label: 'Objet' },
];

function CreateItemModale() {
  const dispatch = useAppDispatch();
  const { item } = useAppSelector((state: any) => state.templateItemsReducer);
  const {
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    reset,
    trigger,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<FieldValues>();
  const requiredRule = { required: 'Ce champs est requis' };

  const form = watch();

  function onSubmit() {
    const data = { ...form };
    clearErrors();
    if (data.picture && data.category) {
      const keys = Object.keys(data);
      const formData = new FormData();
      keys.forEach((key) => {
        let value = data[key];
        if (data[key]?._id && key === 'picture') {
          value = data[key]?._id;
        }
        return formData.append(key, value);
      });

      if (item?._id) {
        return putTemplateItemAction(dispatch, item._id, formData);
      }
      return postTemplateItemsAction(dispatch, formData);
    }
    return null;
  }

  async function handleValidation() {
    let isValid = await trigger();
    const data = getValues();
    if (!data.picture) {
      isValid = false;
      setError('picture', { type: 'custom', message: "l'image est requise" });
    }
    if (!data.category) {
      isValid = false;
      setError('category', { type: 'custom', message: 'le type est requis' });
    }
    if (isValid) handleSubmit(onSubmit)();
  }

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (form.category) {
      clearErrors('category');
    }
  }, [form.category]);

  useEffect(() => {
    if (item) {
      reset(item);
    }
  }, [item]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {item?.picture && form?.color
          && (
            <div
              className={styles.templateItem}
              style={{
                border: `1px solid ${form.color}`,
                backgroundColor: hexToRgba(form.color, 0.5),
              }}
            >
              {item.picture && (
              <img
                src={`${process.env.REACT_APP_API_URL}/${item.picture.path}`}
                alt={item.picture.name}
              />
              )}
            </div>
          )}
        <h3>{item?.name || 'Ajouter un élément'}</h3>
      </div>
      <div className={styles.options}>
        {itemOptions.map((opt) => (
          <button
            key={opt.value}
            type="button"
            onClick={() => setValue('category', opt.value)}
            className={`${styles.btn} ${form.category === opt.value ? styles.active : ''}`}
          >
            {opt.label}
          </button>
        ))}
      </div>
      {errors?.category?.message && <ErrorField message={errors.category.message} />}
      <div className={styles.form}>
        <div className={styles['container-field']}>
          <InputText
            name="name"
            control={control}
            rules={requiredRule}
            label="Nom"
            placeholder="Nom..."
          />
          {errors?.name?.message && <ErrorField message={errors.name.message} />}
        </div>
        <div className={styles['container-field']}>
          <Textarea
            name="description"
            control={control}
            rules={{ }}
            label="description"
            placeholder="Description..."
          />
          {errors?.description?.message && <ErrorField message={errors.description.message} />}
        </div>
        <div className={`${styles['container-field']} ${styles.color}`}>
          <InputColor
            name="color"
            control={control}
            rules={requiredRule}
            label="Couleur"
            placeholder="Description..."
          />
          {errors?.color?.message && <ErrorField message={errors.color.message} />}
        </div>

        <div className={styles.file}>
          <InputFile
            name="picture"
            label={form?.picture?._id ? `${form?.picture?._id}.${form?.picture?.extension}` : 'Image'}
            clearError={() => clearErrors('picture')}
            handleChange={(file) => setValue('picture', file)}
          />
          {errors?.picture?.message && <ErrorField message={errors.picture.message} />}
        </div>

        <div className={styles.submit}>
          <button
            type="button"
            className={`${styles.btn} ${styles.primary}`}
            onClick={() => handleValidation()}
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateItemModale;
