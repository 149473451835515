import React, { useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';

import { IContributor } from '../../types/projects.types';

import { useAppSelector } from '../../hooks/useRedux';

import SceneAside from '../SceneAside';
import LibAside from '../LibAside';

import avatar from '../../assets/images/avatar.png';
import styles from './ProjectAside.module.scss';
import GroupAside from '../GroupAside';

interface ILibAside {
  onDragItem: any,
  onDragItemStart: any,
  setItemModale: any,
  setLinkModale: any,
  setGroupModale: any,
  onClickAdd: any,
  updateItem: any,
}

function ProjectAside({
  onDragItem,
  onDragItemStart,
  setItemModale,
  setLinkModale,
  setGroupModale,
  updateItem,
  onClickAdd,
}: ILibAside) {
  const { project } = useAppSelector((state: any) => state.projectsReducer);
  const { user } = useAppSelector((state: any) => state.authReducer);
  const isMember = user?._id !== project?.owner?._id && project?.contributors.find((c :IContributor) => c.user._id === user?._id)?.role === 'Member';
  const [currentAside, setCurrentAside] = useState('scene');
  return (
    <aside className={styles.container}>
      <div className={styles.header}>
        <NavLink to="/home">
          <BiArrowBack size={20} />
          Retour
        </NavLink>
        <div className={styles.users}>
          <div className={`${styles.user} ${styles.count}`}>
            <p>{project?.contributors?.length}</p>
          </div>
          {project?.contributors.filter((c: IContributor) => c.user).slice(0, 2)?.map((c: IContributor) => (
            <div key={c._id} className={styles.user}>
              <img src={c.user?.avatar?.path ? `${process.env.REACT_APP_API_URL}/${c.user?.avatar?.path}` : avatar} alt="bakalab-avatar" />
            </div>
          ))}
        </div>
      </div>
      {!isMember
        && (
        <div className={styles.nav}>
          <button
            className={currentAside === 'scene' ? styles.active : ''}
            onClick={() => setCurrentAside('scene')}
          >
            Scènes
          </button>
          <button
            className={currentAside === 'group' ? styles.active : ''}
            onClick={() => setCurrentAside('group')}
          >
            Groupes
          </button>
          <button
            className={currentAside === 'lib' ? styles.active : ''}
            onClick={() => setCurrentAside('lib')}
          >
            Objets
          </button>
        </div>
        )}
      {currentAside === 'lib'
        && (
          <LibAside
            onDragItem={onDragItem}
            onDragItemStart={onDragItemStart}
            onClickAdd={onClickAdd}
          />
        )}
      {currentAside === 'group'
        && (
          <GroupAside
            setItemModale={setItemModale}
            setLinkModale={setLinkModale}
            setGroupModale={setGroupModale}
            updateItem={updateItem}
          />
        )}
      {currentAside === 'scene'
        && (
          <SceneAside
            setItemModale={setItemModale}
            setLinkModale={setLinkModale}
          />
        )}
    </aside>
  );
}

export default ProjectAside;
