import {
  configureStore,
} from '@reduxjs/toolkit';

import authReducer from './auth.reducer';
import sceneReducer from './scene.reducer';
import projectsReducer from './projects.reducer';
import fileReducer from './file.reducer';
import templateItemsReducer from './templateItems.reducer';

export const reducers = configureStore({
  reducer: {
    authReducer,
    sceneReducer,
    projectsReducer,
    fileReducer,
    templateItemsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `ApplicationState` and `DispatchType` types from the store itself
export type ApplicationState = ReturnType<typeof reducers.getState>;
export type DispatchType = typeof reducers.dispatch;
