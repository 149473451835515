import React, { KeyboardEvent } from 'react';

import { useForm } from 'react-hook-form';

import { DispatchType } from '../../reducers';
import { IProfileForm } from '../../types/auth.types';

import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/useRedux';

import { putProfileAction } from '../../actions/auth';

import InputFile from '../../components/InputFile';

import {
  InputEmail,
  InputText,
  ErrorField,
  Button,
} from '../../lib/HooksFormFields';

import avatarImg from '../../assets/images/avatar.png';
import styles from './Profile.module.scss';

function Profile() {
  const dispatch: DispatchType = useAppDispatch();
  const { user, isLoading, message } = useAppSelector((state: any) => state.authReducer);
  const requiredRule = { required: 'Ce champs est requis' };
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IProfileForm>({
    defaultValues: {
      firstName: user.profile.firstName,
      lastName: user.profile.lastName,
      email: user.email,
    },
  });

  function onSubmit(data: IProfileForm) {
    putProfileAction(dispatch, data);
  }

  function onSubmitAvatar(avatar: string | null) {
    const values = getValues();
    putProfileAction(dispatch, { ...values, avatar });
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles.container}>
      <h2>Mon profil</h2>
      <div className={styles.row}>
        <div className={styles.col} onKeyDown={handlePressEnter}>
          <h3>Photo de profil</h3>
          <div className={styles['profile-picture']}>
            <div className={styles.avatar}>
              <img src={user?.avatar?.path ? `${process.env.REACT_APP_API_URL}/${user?.avatar?.path}` : avatarImg} alt="bakalab-avatar" />
            </div>
            <div className={styles.actions}>
              <InputFile
                id="avatar"
                label="Changer de photo"
                handleChange={(val) => onSubmitAvatar(val)}
                activeFile={user?.avatar?._id}
              />
            </div>
          </div>
          <h3>Informations</h3>
          <div className={styles['container-field']}>
            <InputText
              name="lastName"
              control={control}
              rules={requiredRule}
              label="Nom"
              placeholder="Nom..."
            />
            {errors?.lastName && <ErrorField message={errors.lastName.message} />}
          </div>
          <div className={styles['container-field']}>
            <InputText
              name="firstName"
              control={control}
              rules={requiredRule}
              label="Prénom"
              placeholder="Prénom..."
            />
            {errors?.firstName && <ErrorField message={errors.firstName.message} />}
          </div>
          <div className={styles['container-field']}>
            <InputEmail
              name="email"
              control={control}
              required
              label="Mail"
              placeholder="Adresse email..."
            />
            {errors.email && <ErrorField message={errors.email.message} />}
          </div>
          <Button
            type="button"
            className="primary"
            isLoading={isLoading}
            handleClick={handleSubmit(onSubmit)}
          >
            Enregistrer
          </Button>
          {message && <p className={styles['message-success']}>{message}</p>}
        </div>
      </div>
    </div>
  );
}

export default Profile;
