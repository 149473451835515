/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import * as PIXI from 'pixi.js';

// import HitAreaShapes from 'hitarea-shapes';
import { Viewport } from 'pixi-viewport';
import {
  distance as fnDistance,
} from './mathUtils';
import { IItem } from '../../types/scene.types';

class GraphicsContainer extends PIXI.Graphics {
  _id: string | undefined;

  dragging: boolean;

  eventData: any;

  links: any;

  slot: string | undefined | null;

  target: string | null;

  source: string | null;

  group: any | null;

  tickerFunction: any;

  constructor(_id?: string) {
    super();
    this._id = _id;
    this.dragging = false;
    this.links = [];
    this.slot = null;
    this.target = null;
    this.source = null;
    this.group = null;
  }

  setSlot(slot: string) {
    this.slot = slot;
  }
}

const debugGrid = false;

export default function runPixi({
  container,
  allowEdit,
  createItem,
  updateItem,
  toggleModalItem,
  setLinkModale,
  title,
}: any) {
  const containerRect = container.getBoundingClientRect();
  const { height } = containerRect;
  const { width } = containerRect;
  const clampSettings = {
    top: -height * 2,
    bottom: height * 2,
    left: -width * 2,
    right: width * 2,
  };
  let activeView = 'select';
  let isDraggable = true;
  // const nodesList : any[] = [];

  let lastRecordedPosition: { x?: number, y?: number } = {};

  const app = new PIXI.Application({
    width,
    height,
    useContextAlpha: false,
    antialias: true,
    transparent: true,
    resolution: window.devicePixelRatio,
    autoDensity: true,
    resizeTo: container,
  });

  const ticker = new PIXI.Ticker();
  ticker.maxFPS = 30;

  ticker.autoStart = false;

  // create viewport
  const viewport = new Viewport({
    screenWidth: width,
    screenHeight: height,
    worldWidth: width * 4,
    worldHeight: height * 4,
    passiveWheel: false,
    interaction: app.renderer.plugins.interaction,
    // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
  });

  const content = new GraphicsContainer('content');
  const patternGrid = new GraphicsContainer('patternGrid');
  const containerHightLight = new GraphicsContainer('container-hightLight');
  const containerGroup = new GraphicsContainer('container-group');
  const containerLink = new GraphicsContainer('container-link');
  const containerNodes = new GraphicsContainer('container-nodes');
  const containerTiles = new GraphicsContainer('container-dots');
  containerNodes.sortableChildren = true;
  containerTiles.interactiveChildren = false;
  content.addChild(containerHightLight);
  if (debugGrid) content.addChild(containerTiles);
  content.addChild(containerGroup);
  content.addChild(containerLink);
  content.addChild(containerNodes);

  viewport.addChild(patternGrid);

  viewport.addChild(content);
  viewport.on('click', (evt: any) => {
    // * Perhaps not the best way to do this
    // * this is how I decide to detect if we are clicking on the viewport
    // * and not on an item or link
    if (typeof evt.target.setZoom !== 'function') return;
    toggleModalItem(null);
  });

  container.innerHTML = '';

  /// INIT RANGE FOR posXOnReleased() &  posYOnReleased()///
  let newLink : any = [];
  const xDistance = Math.abs(clampSettings.left) + Math.abs(clampSettings.right);
  const widthSlot = 150 / 2;
  const countXSlot = Math.round(xDistance / widthSlot);
  const rangeX = new Array(countXSlot + 1).fill(null).map((d, i) => ({ index: i, posX: clampSettings.left + (i * widthSlot) }));
  const yDistance = Math.abs(clampSettings.top) + Math.abs(clampSettings.bottom);
  const heightSlot = 88 / 2;
  const countYSlot = Math.round(yDistance / heightSlot);
  const rangeY = new Array(countYSlot + 1).fill(null).map((d, i) => ({ index: i, posY: clampSettings.top + (i * heightSlot) }));
  const grid: { [key: string]: {
    x: number,
    y: number,
    empty: boolean,
    locked: boolean,
    row: number,
    col: number,
    key: string
  }} = {};

  const shape = new PIXI.Graphics();

  shape.lineStyle(3, 0x242424, 1, 0);
  shape.drawPolygon(
    -75,
    32,
    0,
    -12,
    75,
    32,
    0,
    76,
  );

  const texture = app.renderer.generateTexture(shape, {
    resolution: 3,
  });
  const pattern = new PIXI.TilingSprite(texture, width * 4, height * 4);
  pattern.tilePosition.x -= 75;
  pattern.tilePosition.y += 44;
  pattern.position.x -= width * 2;
  pattern.position.y -= height * 2;

  const shape2 = new PIXI.Graphics();
  shape2.lineStyle(3, 0x242424, 1, 0);
  // node.drawCircle(0, 0, 30);
  shape2.drawPolygon(
    -75,
    32,
    0,
    -12,
    75,
    32,
    0,
    76,
  );

  const texture2 = app.renderer.generateTexture(shape2, {
    resolution: 3,
  });
  const pattern2 = new PIXI.TilingSprite(texture2, width * 4, height * 4);
  pattern2.position.x -= width * 2;
  pattern2.position.y -= height * 2;
  patternGrid.interactive = false;
  // patternGrid.cacheAsBitmap = true;
  pattern.alpha = 0.5;
  pattern2.alpha = 0.5;
  patternGrid.addChild(pattern);
  patternGrid.addChild(pattern2);

  // INIT GRID
  rangeY.forEach((col: any, i: number) => {
    rangeX.forEach((row: any, j: number) => {
      // const color = 0x000FFF; // BLEU
      let isEmpty = true;
      if (
        (j % 2 === 0 && i % 2 === 1)
        || (j % 2 === 1 && i % 2 === 0)
      ) {
        // color = 0xD3D3D3;
        isEmpty = false; // GRIS
      }

      grid[`${row.index}-${col.index}`] = {
        x: row.posX,
        y: col.posY,
        empty: isEmpty,
        locked: !isEmpty,
        row: row.index,
        col: col.index,
        key: `${row.index}-${col.index}`,
      };

      // *********** //
      // DON'T ADD AND DRAW GRID TILES
      // *********** //
      if (!debugGrid) return;

      const tile = new GraphicsContainer(`${row.index}-${col.index}`);
      tile.x = row.posX;
      tile.y = col.posY;
      if (process.env.NODE_ENV !== 'development') {
        tile.cacheAsBitmap = true;
      }
      tile.interactive = false;
      // tile.lineStyle(1, color);
      // tile.beginFill(color);
      // tile.drawCircle(0, 0, 1);
      // tile.endFill();

      const shape = new GraphicsContainer('polygon');
      shape.lineStyle(3, 0x242424, 1, 0);
      // node.drawCircle(0, 0, 30);
      shape.drawPolygon(
        -75,
        32,
        0,
        -12,
        75,
        32,
        0,
        76,
      );
      shape.position.y = -64 / 2;
      tile.addChild(shape);
      // const text = new PIXI.Text(`${row.index}-${col.index}`, {
      //   fontFamily: 'Arial', fontSize: 10, fill: '#FFFFFF', align: 'center',
      // });

      // if (isEmpty) tile.addChild(text);

      if (isEmpty) containerTiles.addChild(tile);
    });
  });

  const drawHightLightTile = (n: any, i?: number) => {
    const color = n.color || 0x474747; // 0xD3D3D3
    const key = n.slot || i;
    const nodeTile = new GraphicsContainer(`group-${key}`);
    nodeTile._id = n._id;
    if (n.slot) {
      nodeTile.x = grid[n.slot].x;
      nodeTile.y = grid[n.slot].y;
    }

    // nodeTile.alpha = grid[n.slot].empty ? 0.2 : 0;
    nodeTile.alpha = 1;

    const shape = new GraphicsContainer(`polygon-group-${key}`);
    shape.lineStyle(3, color);
    // node.drawCircle(0, 0, 30);
    shape.drawPolygon(
      -75,
      32,
      0,
      -12,
      75,
      32,
      0,
      76,
    );
    shape.endFill();
    shape.position.y = -64 / 2;
    nodeTile.addChild(shape);
    containerHightLight.addChild(nodeTile);
  };

  const drawLink = (
    sourceSlot: string,
    targetSlot: string,
    source: string,
    target: string,
    linkData: any,
    activeLabel: boolean = false,
    origin?: string,
  ) => {
    const id = `${source}>${target}`;
    const foundNewLink = containerLink?.children?.find((el :any) => el?._id === 'new-link');
    if (foundNewLink) {
      containerLink.removeChild(foundNewLink);
    }
    const foundOldLink: any = containerLink?.children?.find((el :any) => el?._id === id);
    if (foundOldLink) {
      ticker.remove(foundOldLink.tickerFunction);
      containerLink.removeChild(foundOldLink);
    }
    const foundTarget: any = containerNodes?.children?.find((el :any) => el?._id === target);
    const foundSource: any = containerNodes?.children?.find((el :any) => el?._id === source);
    // * If origin is a selection action
    // * Reset label and highlight base
    if (origin === 'selection') {
      (containerLink?.children || []).forEach((graphic: any) => {
        const label = (graphic?.children || []).find((d: any) => /^label/.test(d._id));
        if (label) graphic.removeChild(label);
      });
      (containerNodes?.children || []).forEach((graphic: any) => {
        const label = (graphic?.children || []).find((d: any) => /^label/.test(d._id));
        if (label) graphic.removeChild(label);
        for (const child of (graphic?.children || [])) {
          if (/^highlight-/.test(child._id)) {
            graphic.removeChild(child);
            break;
          }
        }
      });
    }

    // DRAW LINK
    if (!grid[sourceSlot] || !grid[targetSlot]) {
      return null;
    }
    const link = new GraphicsContainer(id);
    link._id = id;
    link.target = target;
    link.source = source;
    link.lineStyle(linkData.strength, PIXI.utils.string2hex(linkData.color), 1);
    link.moveTo(grid[sourceSlot].x, grid[sourceSlot].y);
    link.lineTo(grid[targetSlot].x, grid[targetSlot].y);
    link.alpha = linkData?.hidden ? 0.2 : 1;
    if (foundTarget?.hidden || foundSource?.hidden) {
      link.alpha = 0.2;
    }

    const x0 = grid[sourceSlot].x;
    const y0 = grid[sourceSlot].y;
    const x1 = grid[targetSlot].x;
    const y1 = grid[targetSlot].y;

    // DRAW CLICKABLE AREA

    const xmid = 0.5 * (x0 + x1);
    const ymid = 0.5 * (y0 + y1);
    // Length of the line
    const length = Math.hypot(x0 - x1, y0 - y1);
    // Alignment angle of the line, i.e. angle with the x axis
    const angle = Math.atan((y1 - y0) / (x1 - x0));
    // 3. ACCOMPANYING RECTANGLE
    const rectangle = new PIXI.Graphics();
    rectangle.beginFill(0xffffff);
    const width = 30;
    rectangle.drawRect(-length / 2, -width / 2, length, width);
    rectangle.endFill();
    rectangle.alpha = 0;
    rectangle.interactive = true;
    rectangle.buttonMode = true;
    rectangle.setTransform(xmid, ymid, 1, 1, angle);

    // DRAW LABEL

    const label = new GraphicsContainer(`label-${id}`);
    label.lineStyle(1, PIXI.utils.string2hex(linkData.color));
    label.beginFill(PIXI.utils.string2hex('#12141A'));
    const textWidth = (linkData.title.length + 2) * 8;
    label.drawRoundedRect(
      -textWidth / 2,
      -10,
      textWidth,
      30,
      5,
    );
    label.endFill();
    label.alpha = activeLabel ? 1 : 0;
    const text = new PIXI.Text(linkData.title, {
      fontFamily: 'Jost-Regular', fontSize: 16, fill: PIXI.utils.string2hex('#FFFFFF'), align: 'center',
    });
    label.x = xmid;
    label.y = ymid;
    label.zIndex = 300;
    text.x = 0;
    text.y = 5;
    text.anchor.set(0.5);
    label.addChild(text);
    isDraggable = true;

    rectangle.on('click', () => {
      isDraggable = false;
      // HIDE EVERY LABEL ON LINKS
      containerLink.children.forEach((graphicsLink: any) => {
        graphicsLink.children.forEach((graphic: any) => {
          if (/^label-/.test(graphic._id)) graphic.alpha = 0;
        });
      });

      // HIDE EVERY LABEL (name of node) ABOVE NODE
      containerNodes.children.forEach((graphicsNode: any) => {
        graphicsNode.children.forEach((graphic: any) => {
          graphic.children.forEach((cg: any) => {
            if (/^label-/.test(cg.parent._id)) cg.parent.alpha = 0;
          });
        });
      });
      setLinkModale({
        sourceSlot,
        targetSlot,
        source,
        target,
        linkData,
      });
    });

    // DRAW ANIMATED CIRCLE

    if (linkData.direction) {
      const circleMark = new GraphicsContainer('polygon');
      const circleWidth = 4 + (linkData.strength / 10);
      circleMark.lineStyle(linkData.strength, PIXI.utils.string2hex(linkData.color));
      circleMark.beginFill(PIXI.utils.string2hex(linkData.color));
      circleMark.drawCircle(0, 0, circleWidth);
      circleMark.endFill();
      circleMark.alpha = 0;

      const circleMark2 = new GraphicsContainer('polygon');
      circleMark2.lineStyle(linkData.strength, PIXI.utils.string2hex(linkData.color));
      circleMark2.beginFill(PIXI.utils.string2hex(linkData.color));
      circleMark2.drawCircle(0, 0, circleWidth);
      circleMark2.endFill();
      circleMark2.alpha = 0;

      const xlen = x1 - x0;
      const ylen = y1 - y0;
      const hlen = Math.sqrt(xlen ** 2 + ylen ** 2);
      let smallerLen = 0;

      if (linkData.direction === 'all') {
        link.addChild(circleMark2);
      }
      link.addChild(circleMark);

      // eslint-disable-next-line no-inner-declarations
      const animateLink = (delta: number) => {
        circleMark.alpha = 1;
        circleMark2.alpha = 1;

        if (smallerLen > hlen) {
          smallerLen = 0;
        } else {
          smallerLen += delta * 5;
        }
        const ratio = smallerLen / hlen;
        const smallerXLen = xlen * ratio;
        const smallerYLen = ylen * ratio;
        let smallerX = x0 + smallerXLen;
        let smallerY = y0 + smallerYLen;
        if (linkData.direction === 'source') {
          smallerX = x1 - smallerXLen;
          smallerY = y1 - smallerYLen;
        }
        circleMark.position.x = smallerX;
        circleMark.position.y = smallerY;
        if (linkData.direction === 'all') {
          circleMark2.position.x = x1 - smallerXLen;
          circleMark2.position.y = y1 - smallerYLen;
        }
      };
      ticker.add(animateLink, PIXI.UPDATE_PRIORITY.LOW);
      link.tickerFunction = animateLink;
    }
    link.addChild(label);
    link.addChild(rectangle);
    return containerLink.addChild(link);
  };

  function getClosestEmptyArraySlot(x: number, y: number) {
    const closestSlots: string[] = [];
    let shortestDistance: number | null = null;
    Object.keys(grid).forEach((key) => {
      const slot = grid[key];
      const currDistance = fnDistance(slot.x, slot.y, x, y);
      if (slot.empty && !slot.locked && shortestDistance && !Number.isNaN(shortestDistance) && currDistance < shortestDistance) {
        shortestDistance = currDistance;
        closestSlots.unshift(key);
      } else if (slot.empty && !slot.locked && shortestDistance === null) {
        shortestDistance = currDistance;
      }
    });
    return closestSlots;
  }

  function getClosestArraySlot(x: number, y: number) {
    const closestSlots: any[] = [];
    Object.keys(grid).forEach((key) => {
      const slot = grid[key];
      if (!slot.locked && x > slot.x - (widthSlot * 2) && x < slot.x + (widthSlot * 2) && y > slot.y - (heightSlot * 2) && y < slot.y + (heightSlot * 2)) {
        closestSlots.unshift(slot);
      }
      if (!slot.locked && x === slot.x && (y === slot.y - (heightSlot * 2) || y === slot.y + (heightSlot * 2))) {
        closestSlots.unshift(slot);
      }
      if (!slot.locked && y === slot.y && (x === slot.x - (widthSlot * 2) || x === slot.x + (widthSlot * 2))) {
        closestSlots.unshift(slot);
      }
    });
    return closestSlots.map((c) => c.key);
  }

  function getClosestGroup(slot: any) {
    // const activesSlots: any[] = [];
    let slotArray = getClosestArraySlot(slot.x, slot.y);
    const keys: string[] = [...slotArray];
    let activeSlots :any = [];
    slotArray.map((k) => grid[k]).forEach((slot) => {
      if (!slot.empty) {
        activeSlots.push(slot);
      }
      if (!keys.find((k) => k === slot.key)) {
        keys.push(slot.key);
      }
    });
    let findActiveNodes = false;
    if (activeSlots.length) {
      findActiveNodes = true;
    }
    while (findActiveNodes) {
      const newSlots : any = [];
      // eslint-disable-next-line no-loop-func
      activeSlots.forEach((item: any) => {
        slotArray = getClosestArraySlot(item.x, item.y).map((k) => grid[k]);
        slotArray.forEach((slot) => {
          if (!keys.find((k) => k === slot.key)) {
            keys.push(slot.key);
            if (!slot.empty && slot.key !== item.key) {
              newSlots.push(slot);
            }
          }
        });
      });
      activeSlots = newSlots;
      if (newSlots.length === 0) {
        findActiveNodes = false;
      }
    }

    return keys;
  }

  function getClosestEmptySlotAt(x: number, y: number) {
    const closestSlots: string[] = getClosestEmptyArraySlot(x, y);
    let index = 0;
    let slot = grid[closestSlots[index]];
    while (slot && (slot.empty === false || slot.locked)) {
      index += 1;
      slot = grid[closestSlots[index]];
    }

    return slot ? {
      ...slot,
      key: closestSlots[index],
      keyX: closestSlots[index].split('-')[0],
      keyY: closestSlots[index].split('-')[1],
    } : null;
  }

  function hightLightClosest(position: { x: number, y: number }) {
    const closestSlot = getClosestEmptySlotAt(position.x, position.y);
    if (containerHightLight?.children) {
      containerHightLight.removeChildren();
    }
    let closestGroup : String[] = [];
    if (closestSlot) {
      closestGroup = getClosestGroup(closestSlot);
      if (closestGroup.length > 0) {
        if (closestGroup) {
          closestGroup.forEach((id : any, i: number) => {
            drawHightLightTile({ slot: id, _id: closestGroup.join(',') }, i);
          });
        }
        return closestGroup;
      }
    }
    return closestGroup;
  }

  function drawGroupTiles(n: any) {
    const groupId = n?.group?._id && `${n._id}-${n.group._id}`;
    const foundGroup = containerGroup?.children?.filter((el :any) => el?._id.includes(n._id));
    if (foundGroup) {
      foundGroup.forEach((g: any) => {
        containerGroup.removeChild(g);
      });
    }
    const groupFound = getClosestArraySlot(grid[n.slot]?.x, grid[n.slot]?.y);
    if (groupFound) {
      groupFound.forEach((g: any) => {
        if (groupId) {
          const group = new GraphicsContainer(groupId);
          group._id = groupId;
          group.alpha = 1;
          group.x = grid[g].x;
          group.y = grid[g].y;
          const groupHightlight = new GraphicsContainer(`group-${g}-${groupId}`);
          groupHightlight.lineStyle(2, PIXI.utils.string2hex(n.group.color), 0.3);
          groupHightlight.beginFill(PIXI.utils.string2hex(n.group.color));
          groupHightlight.drawPolygon(
            -75,
            32,
            0,
            -12,
            75,
            32,
            0,
            76,
          );
          groupHightlight.endFill();
          groupHightlight.alpha = n.hidden ? 0.1 : 0.3;
          groupHightlight.position.y = -64 / 2;
          group.addChild(groupHightlight);
          containerGroup.addChild(group);
        }
      });
    }
  }

  function onDragStart(node: any, evt: any) {
    evt.stopPropagation();
    if (!allowEdit) {
      return;
    }
    viewport.plugins.pause('drag');

    // RESET ITEM MODAL WHEN START DRAGING
    toggleModalItem(null);
    if (activeView === 'select' && isDraggable && node.isDraggable) {
      // DISABLE INTERACTIVITY ON OTHER NODE DURING DRAG
      const otherNodes = containerNodes.children.filter((graphicNode: any) => graphicNode._id !== node._id);
      otherNodes.forEach((graphicNode) => {
        graphicNode.interactive = false;
      });

      const foundNewLink = containerLink?.children?.find((el :any) => el?._id === 'new-link');
      if (foundNewLink) {
        containerLink.removeChild(foundNewLink);
      }
      const position = evt?.data?.getLocalPosition(node.parent);
      lastRecordedPosition = { ...position };
      node.eventData = evt.data;
      newLink = [];
      if (node.slot) {
        grid[node.slot].empty = true;
      }
      node.alpha = 1;
      node.dragging = true;
    }
  }

  function onDragMove(node: any) {
    if (node.dragging && isDraggable) {
      const newPosition = node?.eventData?.getLocalPosition(node.parent);
      node.x = newPosition.x;
      node.y = newPosition.y;
      if (node.slot) {
        grid[node.slot].empty = true;
      }
      containerLink?.children?.forEach((el :any) => {
        if (el._id.includes(node._id)) {
          el.alpha = 0;
        }
      });
      if (node?.eventData && lastRecordedPosition.x && lastRecordedPosition.y) {
        const distance = fnDistance(
          lastRecordedPosition.x,
          lastRecordedPosition.y,
          newPosition.x,
          newPosition.y,
        );
        if (distance > 80) {
          hightLightClosest(newPosition);
        }
      }
      const foundGroup = containerGroup?.children?.filter((el :any) => el?._id.includes(node._id));
      if (foundGroup) {
        foundGroup.forEach((g: any) => {
          containerGroup.removeChild(g);
        });
      }
    }
  }

  async function onDragEnd(node: any, evt: any) {
    evt.stopPropagation();
    let distance = 0;
    if (lastRecordedPosition.x && lastRecordedPosition.y) {
      const newPosition = node?.eventData?.getLocalPosition(node.parent);
      node.x = newPosition.x;
      node.y = newPosition.y;
      distance = fnDistance(
        lastRecordedPosition.x,
        lastRecordedPosition.y,
        newPosition.x,
        newPosition.y,
      );
    }

    // * * If drag for less than distance 10
    // * * trigger toggleModalItem
    // * * drag from less than 10 is considered as "click"
    if (distance < 10) {
      toggleModalItem(node._id);
    }

    if (activeView === 'select' && isDraggable && node.dragging) {
      // DISABLE INTERACTIVITY ON OTHER NODE DURING DRAG
      const otherNodes = containerNodes.children.filter((graphicNode: any) => graphicNode._id !== node._id);
      otherNodes.forEach((graphicNode) => {
        graphicNode.interactive = true;
      });

      // UPDATE STATE OF THE SLOT WHEN DRAG END
      const newPosition = node?.eventData?.getLocalPosition(node.parent);
      const slot = getClosestEmptySlotAt(newPosition.x, newPosition.y);
      const group = hightLightClosest(newPosition);
      // if (group.length > 0 && containerHightLight?.children) {
      //   containerHightLight.removeChildren();
      // }

      // * If slot change the item is updated
      // * and the drawTile() is trigger,
      // * (and drawing links is manage by drawTile())
      if (node.slot !== slot?.key) {
        await updateItem({ _id: node._id, slot: slot?.key });
      } else if (node.slot === slot?.key) { // * Redraw draw link when slot doesnt change
        // * Draw links to from the current node to target
        if (node.links.length > 0) {
          node.links.forEach((link :any) => {
            const target : any = containerNodes?.children?.find((el :any) => el?._id === link.target);
            if (target?.slot && node.slot && node?._id && target?._id && link) {
            // eslint-disable-next-line no-use-before-define
              drawLink(node.slot, target.slot, node._id, target._id, link, false);
            }
          });
        }

        // * Draw links to from the other node to current node
        containerNodes?.children?.forEach((el: any) => {
          const foundLink = el.links.find((l: any) => l.target === node._id);
          if (el.slot && node?.slot && el?._id && node?._id && foundLink) {
            drawLink(el.slot, node.slot, el._id, node._id, foundLink, false);
          }
        });
      }

      if (group.length > 0 && slot && newPosition) {
        node.setSlot(slot.key);
        grid[slot.key].empty = false;
        node.slot = slot.key;
        node.x = slot.x;
        node.y = slot.y;
        node.zIndex = slot.y;
      }

      node.dragging = false;
      node.isDraggable = true;
      node.eventData = null;
      node.alpha = 1;
      drawGroupTiles(node);
      // // REQUEST TO PUT ITEM POSITION

      lastRecordedPosition = {};
      // if (closestTile) closestTile.alpha = 0;
    }

    if (containerHightLight?.children) {
      containerHightLight.removeChildren();
    }

    viewport.plugins.resume('drag');
  }

  function findLink(slot: any) {
    if (activeView !== 'link') {
      return;
    }
    let node = null;
    const id = 'new-link';
    if (slot) {
      node = grid[slot];
    }
    const foundNewLink = containerLink?.children?.find((el :any) => el?._id === 'new-link');
    if (foundNewLink) {
      containerLink.removeChild(foundNewLink);
    }
    if (newLink.length > 0 && node) {
      const link = new GraphicsContainer(id);
      const source : any = containerNodes?.children?.find((el :any) => el?.slot === newLink[0].key);
      if (!source) {
        return;
      }
      link._id = id;
      link.lineStyle(1, PIXI.utils.string2hex(source.color), 0.5);
      link.moveTo(newLink[0].x, newLink[0].y);
      link.lineTo(node.x, node.y);
      containerLink.addChild(link);
      newLink[1] = node;
    }
  }

  container.appendChild(app.view);

  app.stage.addChild(viewport);

  ticker.start();

  // activate plugins
  viewport.drag().pinch().wheel().decelerate()
    .clampZoom({
      minScale: 0.4,
      maxScale: 2,
    });
  viewport.scaled = 0.6;
  viewport.clamp(clampSettings);

  viewport.moveCenter(
    width / 2,
    height / 2,
  );

  async function exportScene() {
    app.stop();
    // const url = await app.renderer.plugins.extract.base64(content);
    const blob = app.renderer.plugins.extract.image(content).src;
    blob.replace('image/png', 'image/octet-stream');
    const a = document.createElement('a');
    document.body.append(a);
    a.download = `${title}.png`;
    a.href = blob;
    a.click();
    a.remove();
    app.start();
  }

  const moveToCenter = (items : any[]) => {
    // const x = width / 2;
    // const y = height / 2;
    const x = items.map((i) => i.slot && grid[i.slot] && grid[i.slot].x - (64 / 2)).reduce((a, b) => a + b, 0) / items.length;
    const y = items.map((i) => i.slot && grid[i.slot] && grid[i.slot].y - (64 / 2)).reduce((a, b) => a + b, 0) / items.length;
    viewport.moveCenter(
      x + 200,
      y,
    );
  };

  const deleteTile = (id: string) => {
    const foundNode : any = containerNodes?.children?.find((el :any) => el?._id === id);
    if (foundNode) {
      grid[foundNode.slot].empty = true;
      containerNodes.removeChild(foundNode);
      const closestTile = containerTiles.children.find((tile: any) => tile._id === foundNode.slot);
      if (closestTile) {
        closestTile.alpha = 0;
      }
    }
    const foundGroup = containerGroup?.children?.filter((el :any) => el?._id.includes(id));
    if (foundGroup) {
      foundGroup.forEach((g: any) => {
        containerGroup.removeChild(g);
      });
    }

    const foundNewLinks = containerLink?.children?.filter((el :any) => el._id.includes(id));
    if (foundNewLinks.length) {
      foundNewLinks.forEach((link) => {
        containerLink.removeChild(link);
      });
    }
  };

  const deleteLink = (id: string) => {
    const foundNewLinks = containerLink?.children?.filter((el :any) => el._id.includes(id));
    if (foundNewLinks.length) {
      foundNewLinks.forEach((link) => {
        containerLink.removeChild(link);
      });
    }
  };

  const drawTile = (n: any, activeLabel: boolean = false, i?: number) => {
    const boundDrag = onDragMove.bind(n);
    const key = n.slot || i;
    const foundNode = containerNodes?.children?.find((el :any) => el?._id === n._id);
    if (foundNode) {
      containerNodes.removeChild(foundNode);
    }

    drawGroupTiles(n);

    const node: any = new GraphicsContainer(`slot-${key}`);
    node._id = n._id;
    node.links = n.links;
    node.color = n.color;
    node.isDraggable = true;
    node.group = n.group;
    node.hidden = !!n.hidden;
    const source : any = n;

    // * Hide every label on links
    containerLink.children.forEach((graphicsLink: any) => {
      graphicsLink.children.forEach((graphic: any) => {
        if (/^label-/.test(graphic._id)) graphic.alpha = 0;
      });
    });

    // * Hide every label (name of node) above node
    containerNodes.children.forEach((graphicsNode: any) => {
      graphicsNode.children.forEach((graphic: any) => {
        if (/^highlight-/.test(graphic._id)) graphic.alpha = 0;
        graphic.children.forEach((cg: any) => {
          if (/^label-/.test(cg.parent._id)) cg.parent.alpha = 0;
        });
      });
    });

    // * Draw links to from the current node to target
    if (node.links.length > 0) {
      node.links.forEach((l :any) => {
        const link = { ...l, hidden: n.hidden };
        const target : any = containerNodes?.children?.find((el :any) => el?._id === link.target);
        if (target?.slot && source.slot && source?._id && target?._id && link) {
          drawLink(source.slot, target.slot, source._id, target._id, link, activeLabel);
        }
      });
    }

    // * Draw links to from the other node to current node
    containerNodes?.children?.forEach((el: any) => {
      const foundLink = el.links.find((l: any) => l.target === n._id);
      if (el.slot && source?.slot && el?._id && source?._id && foundLink) {
        const link = { ...foundLink, hidden: n.hidden };
        drawLink(el.slot, source.slot, el._id, source._id, link, activeLabel);
      }
    });

    if (n.slot && grid[n.slot]) {
      node.setSlot(n.slot);
      grid[n.slot].empty = false;
      node.x = grid[n.slot].x;
      node.y = grid[n.slot].y;
      node.zIndex = grid[n.slot].y;
    } else {
      node.x = width / 2;
      node.y = height / 2;
    }
    node.alpha = n.hidden ? 0.1 : 1;
    const shape = new GraphicsContainer('polygon');
    shape.lineStyle(3, PIXI.utils.string2hex(n.color), 1, 0);
    shape.beginFill(PIXI.utils.string2hex('#12141A'), 0.2);
    shape.drawPolygon(
      -75,
      32,
      0,
      -12,
      75,
      32,
      0,
      76,
    );
    shape.endFill();
    shape.position.y = -64 / 2;
    node.addChild(shape);

    const hitarea = new PIXI.Polygon(
      -75,
      0,
      0,
      -44,
      75,
      0,
      0,
      44,
    );
    node.hitArea = hitarea;
    if (activeLabel) {
      const shapeHighlight = new GraphicsContainer(`highlight-${node._id}`);
      shapeHighlight.lineStyle(2, PIXI.utils.string2hex(n.color));
      shapeHighlight.beginFill(PIXI.utils.string2hex(n.color));
      shapeHighlight.drawPolygon(
        -75,
        32,
        0,
        -12,
        75,
        32,
        0,
        76,
      );
      shapeHighlight.endFill();
      shapeHighlight.alpha = 0.3;
      shapeHighlight.position.y = -64 / 2;
      node.addChild(shapeHighlight);
    }

    node
      .on('mouseover', () => findLink(n?.slot))
      .on('click', (evt: any) => {
        /*
        * * Event click is used for drawing links
        * * WARNING: this is not use to open <ItemModale />
        * * because of an issue due to conflict between click and mouseup event.
        * * Opening modal is manage by onDragEnd event
        * */
        evt.stopPropagation();
        if (!grid[n.slot].empty && activeView === 'link') {
          if (newLink.length === 0) {
            newLink.push(grid[n.slot]);
          }
          if (newLink.length === 2) {
            const foundSource : any = containerNodes?.children?.find((el :any) => el?.slot === newLink[0].key);
            const foundTarget : any = containerNodes?.children?.find((el :any) => el?.slot === newLink[1].key);
            const links = [...foundSource.links];
            if (foundTarget?._id !== foundSource?._id && foundSource?._id && !node.links.find((l: any) => l.target === foundTarget._id)) {
              const link = {
                target: foundTarget._id,
                title: 'titre du lien',
                description: '',
                type: 'value',
                direction: 'target',
                color: foundSource.color,
                style: 'solid',
                strength: 1,
              };
              links.push(link);
              drawLink(newLink[0].key, newLink[1].key, foundSource?._id, foundTarget?._id, link);
            }
            updateItem({ _id: foundSource._id, links: [...links] });
            newLink = [];
          }
        }
      })
      .on('mousedown', (evt: any) => onDragStart(node, evt))
      // events for drag end
      .on('mouseup', (e: any) => onDragEnd(node, e))
      .on('mouseupoutside', (e: any) => onDragEnd(node, e))
      // events for drag move
      .on('mousemove', () => boundDrag(node));

    if (n?.templateItem?.picture?.path) {
      const tilePicture = new GraphicsContainer(`picture-${key}`);
      const pictureTexture = PIXI.Texture.from(`${process.env.REACT_APP_API_URL}/${n?.templateItem.picture.path}`);
      const spritePicture = PIXI.Sprite.from(pictureTexture);
      spritePicture.width = 175;
      spritePicture.height = 175;
      tilePicture.addChild(spritePicture);
      tilePicture.position.y -= 130;
      tilePicture.position.x -= 175 / 2;

      node.addChild(tilePicture);
    }

    if (activeLabel && n?.title?.length > 0) {
      const label = new GraphicsContainer(`label-${key}`);
      label.beginFill(PIXI.utils.string2hex('#12141A'));
      label.lineStyle(1, PIXI.utils.string2hex(n.color));
      const textWidth = n.title.length * 12;
      label.drawRoundedRect(
        -textWidth / 2,
        -150,
        textWidth,
        30,
        5,
      );
      label.endFill();
      label.zIndex = grid[n.slot].y + 8;
      const text = new PIXI.Text(n?.title, {
        fontFamily: 'Jost-Regular', fontSize: 16, fill: '#FFFFFF', align: 'center',
      });
      text.x = 0;
      text.y = -135;
      text.anchor.set(0.5);

      label.addChild(text);
      node.addChild(label);
    }

    containerNodes.addChild(node);

    node.interactive = true;
    node.buttonMode = true;
  };

  return {
    destroy: () => {
      ticker.destroy();
      app.destroy(true, {
        children: true,
      });
    },
    setActiveView: (value : string) => {
      activeView = value;
    },
    setIsDraggable: (value : boolean) => {
      isDraggable = value;
    },
    moveToCenter: (nodes: any) => {
      moveToCenter(nodes);
    },
    exportScene: () => {
      exportScene();
    },
    drawTile: (n: any, label: boolean) => {
      drawTile(n, label);
    },
    drawLink: (sourceSlot: string, targetSlot: string, sourceId: string, targetId: string, link: any, label: boolean, origin?:string) => {
      drawLink(sourceSlot, targetSlot, sourceId, targetId, link, label, origin);
    },
    deleteLink: (id : string) => deleteLink(id),
    deleteTile: (id : string) => deleteTile(id),
    hightLightClosest: (position: { x: number, y: number }) => {
      const worldPosition = viewport.toWorld(position.x, position.y);
      hightLightClosest(worldPosition);
    },
    dragThenAdd: async (position: { x: number, y: number }, item: IItem) => {
      const worldPosition = viewport.toWorld(position.x, position.y);
      const closestSlot = getClosestEmptySlotAt(worldPosition.x, worldPosition.y);
      const newItem = await createItem({ ...item, slot: closestSlot?.key });
      drawTile({ ...newItem });
      return newItem;
    },
  };
}
