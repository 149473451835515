import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { DispatchType } from '../../../reducers';
import {
  resetPassword,
  resetError,
} from '../../../actions/auth';

import ResetForm from '../../../components/AuthForm/ResetForm';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import styles from '../Auth.module.scss';

type FormValues = {
  password: string
};

function Reset() {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useAppSelector((state: any) => state.authReducer);
  const { token } = useParams<{ token : string }>();
  const [succeed, setSucceed] = useState(false);
  const successMessage = 'Votre mot de passe a été changé.';

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (authReducer.user?._id) navigate('/home');
  }, [
    authReducer.user,
  ]);

  function submit(data: FormValues) {
    resetPassword(dispatch, {
      token,
      password: data.password,
    }).then(() => {
      setSucceed(true);
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.header}>
          <h2>Nouveau mot de passe ?</h2>
        </div>
        <ResetForm
          submit={(data) => submit(data)}
          errorMessage={authReducer.error}
          successMessage={succeed ? successMessage : ''}
        />
      </div>
    </div>
  );
}

export default Reset;
