import { Action } from '../types';
import {
  TEMPLATE_ITEM_GET,
  TEMPLATE_ITEM_LOADING,
  TEMPLATE_ITEM_ERROR,
  TEMPLATE_ITEM_POST,
  TEMPLATE_ITEM_PUT,
  TEMPLATE_ITEM_EDIT_MODALE,
} from '../actions/actions';
import { TemplateItemState } from '../types/templateItem.types';

const initialState: TemplateItemState = {
  templateItems: [],
  isLoading: false,
  item: null,
  error: null,
};

const templateItemReducer = (
  state: TemplateItemState = initialState,
  action: Action,
): TemplateItemState => {
  let updatedState = { ...state };
  const list = [...state.templateItems];
  const index = list.findIndex((t) => t._id === action?.payload?._id);
  switch (action.type) {
    case TEMPLATE_ITEM_GET:
      updatedState = {
        ...state,
        templateItems: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case TEMPLATE_ITEM_PUT:
      if (index !== -1) {
        list[index] = action?.payload;
      }
      updatedState = {
        ...state,
        item: action.payload,
        templateItems: list,
        isLoading: false,
      };
      break;
    case TEMPLATE_ITEM_EDIT_MODALE:
      updatedState = { ...state, item: action.payload };
      break;
    case TEMPLATE_ITEM_LOADING:
      updatedState = { ...state, isLoading: true };
      break;
    case TEMPLATE_ITEM_POST:
      updatedState = {
        ...state,
        error: null,
        templateItems: [action.payload, ...state.templateItems],
        item: action.payload,
        isLoading: false,
      };
      break;
    case TEMPLATE_ITEM_ERROR:
      updatedState = {
        ...state,
        error: null,
        isLoading: false,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default templateItemReducer;
