// AUTH ACTIONS
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';
// FILES ACTIONS
export const POST_FILE = 'POST_FILE';
export const FILE_LOADING = 'FILE_LOADING';
export const FILE_ERROR = 'FILE_ERROR';
// TEMPLATE_ITEM ACTIONS
export const TEMPLATE_ITEM_GET = 'TEMPLATE_ITEM_GET';
export const TEMPLATE_ITEM_LOADING = 'TEMPLATE_ITEM_LOADING';
export const TEMPLATE_ITEM_ERROR = 'TEMPLATE_ITEM_ERROR';
export const TEMPLATE_ITEM_POST = 'TEMPLATE_ITEM_POST';
export const TEMPLATE_ITEM_PUT = 'TEMPLATE_ITEM_PUT';
export const TEMPLATE_ITEM_EDIT_MODALE = 'TEMPLATE_ITEM_EDIT_MODALE';
// PROJECTS ACTIONS
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const POST_PROJECT = 'POST_PROJECT';
export const PUT_PROJECT = 'PUT_PROJECT';
export const SEARCH_PROJECT = 'SEARCH_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const GET_USERS = 'GET_USERS';
export const ERROR_PROJECT = 'ERROR_PROJECT';
export const LOADING_PROJECT = 'LOADING_PROJECT';
export const INVIT_USER = 'INVIT_USER';

// GROUP ACTIONS
export const GET_PROJECT_GROUPS = 'GET_PROJECT_GROUPS';
export const POST_GROUP = 'POST_GROUP';
export const PUT_GROUP = 'PUT_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';

// SCENE ACTIONS
export const PUT_SCENE = 'PUT_SCENE';
export const GET_SCENE = 'GET_SCENE';
export const RESET_SCENE = 'RESET_SCENE';
export const POST_SCENE = 'POST_SCENE';
export const DELETE_SCENE = 'DELETE_SCENE';
export const ERROR_SCENE = 'ERROR_SCENE';
// ITEMS ACTIONS
export const LOADING_ITEM = 'LOADING_ITEM';
export const ERROR_ITEM = 'ERROR_ITEM';
export const GET_SCENE_ITEMS = 'GET_SCENE_ITEMS';

export const POST_ITEM = 'POST_ITEM';
export const PUT_ITEM = 'PUT_ITEM';
export const SET_ITEM_MODALE = 'SET_ITEM_MODALE';
export const SET_GROUP_MODALE = 'SET_GROUP_MODALE';
export const SET_LINK_MODALE = 'SET_LINK_MODALE';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_ACTIVE_TOOL = 'SET_ACTIVE_TOOL';
