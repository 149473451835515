import { Action } from '../types';
import {
  POST_FILE,
  FILE_LOADING,
  FILE_ERROR,
} from '../actions/actions';
import { FileState } from '../types/file.types';

const initialState: FileState = {
  file: null,
  isLoading: false,
  error: null,
};

const fileReducer = (
  state: FileState = initialState,
  action: Action,
): FileState => {
  let updatedState = { ...state };
  switch (action.type) {
    case POST_FILE:
      updatedState = {
        ...state,
        file: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case FILE_LOADING:
      updatedState = { ...state, isLoading: true };
      break;
    case FILE_ERROR:
      updatedState = {
        ...state,
        error: null,
        isLoading: false,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default fileReducer;
