import React, { KeyboardEvent, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useParams, useNavigate } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';
import { DispatchType } from '../../reducers';

import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/useRedux';

import InputFile from '../../components/InputFile';

import {
  InputText,
  ErrorField,
  Button,
} from '../../lib/HooksFormFields';

import avatarImg from '../../assets/images/avatar.png';
import styles from './EditProject.module.scss';
import { IProject } from '../../types/projects.types';
import { deleteProjectAction, getProjectAction, putProjectAction } from '../../actions/projects';

function EditProject() {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { project, isLoading, message } = useAppSelector((state: any) => state.projectsReducer);
  const requiredRule = { required: 'Ce champs est requis' };
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<IProject>();

  useEffect(() => {
    if (projectId) {
      getProjectAction(dispatch, projectId);
    }
  }, []);

  useEffect(() => {
    if (project?._id) {
      reset({ ...project });
    }
  }, [project]);

  function onSubmit() {
    const data = getValues();
    if (projectId && data.title) {
      putProjectAction(dispatch, projectId, { ...data });
    }
  }

  function onSubmitThumbnail(thumbnail: string | null) {
    const values = getValues();
    if (projectId && thumbnail) {
      putProjectAction(dispatch, projectId, { ...values, thumbnail });
    }
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  async function handleDelete() {
    const deleted = await deleteProjectAction(dispatch, project._id);
    if (deleted) {
      console.log(deleted);
      navigate('/home/project/');
    }
  }

  return (
    <div className={styles.container}>
      {project?._id
      && (
        <>
          <h2>{project?.title}</h2>
          <div className={styles.row}>
            <div className={styles.col} onKeyDown={handlePressEnter}>
              <h3>Vignette</h3>
              <div className={styles.picture}>
                {project?.thumbnail?.path
                  && (
                  <div className={styles.thumbnail}>
                    <img src={project?.thumbnail?.path ? `${process.env.REACT_APP_API_URL}/${project?.thumbnail?.path}` : avatarImg} alt="bakalab-avatar" />
                  </div>
                  )}
                <div className={styles.actions}>
                  <InputFile
                    id="thumbnail"
                    label={project?.thumbnail?._id ? 'Changer la vignette' : 'Ajouter une vignette'}
                    handleChange={(val) => onSubmitThumbnail(val)}
                    activeFile={project?.thumbnail?._id}
                  />
                </div>
              </div>
              <h3>Informations</h3>
              <div className={styles['container-field']}>
                <InputText
                  name="title"
                  control={control}
                  rules={requiredRule}
                  label="Nom du dossier"
                  placeholder="Nom du dossier..."
                />
                {errors?.title && <ErrorField message={errors.title.message} />}
              </div>
              <div className={styles.btns}>
                <Button
                  type="button"
                  className="primary"
                  isLoading={isLoading}
                  handleClick={handleSubmit(onSubmit)}
                >
                  Enregistrer
                </Button>
                {project?._id
                  && (
                  <button
                    type="button"
                    className={styles.delete}
                    onClick={() => handleDelete()}
                  >
                    Supprimer
                    <AiOutlineDelete />
                  </button>
                  )}
              </div>
              {message && <p className={styles['message-success']}>{message}</p>}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EditProject;
