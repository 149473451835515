import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { IProfile } from '../../types/auth.types';
import { IContributor, IContributorForm } from '../../types/projects.types';
import { getUsersAction, postInviteUserAction, putProjectAction } from '../../actions/projects';
import { DispatchType } from '../../reducers';
import validateEmail from '../../helpers/utils';

import avatar from '../../assets/images/avatar.png';
import styles from './UserModale.module.scss';
import { StyledSelect } from '../../lib/HooksFormFields';

const projectRoles = [
  { label: 'Membre', value: 'Member' },
  { label: 'Editeur', value: 'Admin' },
];

function UserRow(
  {
    user,
    role,
  }
  :
  {
    user: IProfile,
    role?: string | null,
  },
): JSX.Element {
  const { project } = useAppSelector((state: any) => state.projectsReducer);
  const authId = useAppSelector((state: any) => state.authReducer)?.user?._id;
  const { control, watch } = useForm({
    defaultValues: {
      role: projectRoles.find((r) => r.value === role),
    },
  });
  const dispatch: DispatchType = useAppDispatch();
  const form = watch();

  function handleChangeRole(newRole: string) {
    const contributors = project.contributors.map((c: IContributor) => ({ user: c.user._id, role: c.role }));
    const foundIndex = contributors.findIndex((c: IContributorForm) => c?.user === user._id);
    if (foundIndex !== -1) {
      contributors[foundIndex].role = newRole;
      putProjectAction(dispatch, project._id, { contributors });
    }
  }

  function handleChangeContributors(userId: string) {
    let contributors = project.contributors.filter((c : IContributor) => c.user).map((c: IContributor) => ({ user: c.user._id, role: c.role }));
    const foundId = contributors.find((c: IContributorForm) => c.user === userId)?.user;
    if (foundId) {
      contributors = contributors.filter((c: IContributorForm) => c.user !== userId);
    } else {
      contributors.push({ user: userId, role: 'Member' });
    }
    putProjectAction(dispatch, project._id, { contributors });
  }

  useEffect(() => {
    if (form?.role?.value && form?.role?.value !== role) {
      handleChangeRole(form.role.value);
    }
  }, [form?.role]);

  return (
    <div className={styles.user}>
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <img src={user?.avatar?.path ? `${process.env.REACT_APP_API_URL}/${user?.avatar?.path}` : avatar} alt="bakalab-avatar" />
        </div>
        <div>
          <p>
            {user?.profile?.firstName}
            {' '}
            {user?.profile?.lastName}
            <br />
            <span>{user?.email}</span>
          </p>
        </div>
      </div>
      {role && user._id !== project?.owner?._id && user._id !== authId
        && (
          <div className={styles.actions}>
            <div className={styles.select}>
              <StyledSelect
                name="role"
                control={control}
                options={projectRoles}
                rules={{ }}
              />
            </div>
            <button
              type="button"
              onClick={() => handleChangeContributors(user._id)}
              className={`${styles.delete} ${styles['primary-icon']}`}
            >
              <AiOutlineDelete />
            </button>
          </div>
        )}
      {!role && (
        <div className={styles.actions}>
          <button
            type="button"
            onClick={() => handleChangeContributors(user._id)}
            className={`${styles.delete} ${styles.primary}`}
          >
            Inviter
          </button>
        </div>
      )}
    </div>
  );
}

function UserModale() {
  const dispatch: DispatchType = useAppDispatch();
  const { project, users } = useAppSelector((state: any) => state.projectsReducer);
  const [search, setSearch] = useState('');
  const [invitedEmail, setInvitedEmail] = useState<string | null>();
  const [filteredUsers, setFilteredUser] = useState([]);

  const getUser = useCallback(() => {
    getUsersAction(dispatch);
  }, [dispatch]);

  function inviteUser() {
    if (invitedEmail) {
      postInviteUserAction(dispatch, { email: invitedEmail, project: project._id });
      setInvitedEmail(null);
      setSearch('');
    }
  }

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (search) {
      const list = users.filter((u: IProfile) => {
        const isContrib = project.contributors.find((c: IContributor) => c.user?._id === u._id);
        if (isContrib) {
          return null;
        }
        if (u.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
          return u;
        }
        if (u.profile.firstName.toLowerCase().includes(search.toLowerCase())) {
          return u;
        }
        if (u.email.toLowerCase().includes(search.toLowerCase())) {
          return u;
        }
        return null;
      });
      setFilteredUser(list);
      if (validateEmail(search) && list.length === 0) {
        setInvitedEmail(search);
      } else {
        setInvitedEmail(null);
      }
    } else {
      setFilteredUser([]);
    }
  }, [search, project.contributors]);

  return (
    <div className={styles.container}>
      <h4>Partager avec des personnes</h4>
      <div className={styles.search}>
        <input
          type="text"
          value={search}
          placeholder="Rechecher"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={styles.list}>
        {(invitedEmail)
          && (
            <div className={styles.send}>
              <p>{search}</p>
              <button
                onClick={() => inviteUser()}
                type="button"
                className={`${styles.delete} ${styles.primary}`}
              >
                Inviter
              </button>
            </div>
          )}
        {filteredUsers?.map((u : IProfile) => <UserRow key={u._id} user={u} />)}
        {search && !invitedEmail && !validateEmail(search) && filteredUsers.length === 0 && <p className={styles.message}>Aucun résultat ne correspond à votre recherche</p>}
      </div>
      <label>Les membres du projet</label>
      <div className={styles.list}>
        {project?.contributors?.map((c : IContributor) => <UserRow key={c.user?._id} user={c.user} role={c.role} />)}
      </div>
    </div>
  );
}

export default UserModale;
