/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getData,
  postData,
  deleteData,
  putData,
} from './index';
import { DispatchType } from '../reducers';
import {
  DELETE_PROJECT,
  ERROR_PROJECT,
  GET_PROJECTS,
  LOADING_PROJECT,
  GET_PROJECT,
  POST_PROJECT,
  PUT_PROJECT,
  SEARCH_PROJECT,
  GET_USERS,
  SET_TOAST,
  INVIT_USER,
  RESET_SCENE,
} from './actions';
import {
  IContributor,
  IInviteForm,
  IProject,
  IProjectForm,
} from '../types/projects.types';
import { IUser } from '../types/auth.types';

export const getProjectsAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/project';
  let projects;
  dispatch({
    type: LOADING_PROJECT,
  });
  dispatch({
    type: RESET_SCENE,
  });

  const promise = await getData(ERROR_PROJECT, url, dispatch, true).then((response: any) => {
    projects = response.data.projects;
  });
  dispatch({
    type: GET_PROJECTS,
    payload: projects,
  });
  return promise;
};

export const getProjectAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/project/${id}`;
  let project: IProject | null = null;
  dispatch({
    type: LOADING_PROJECT,
  });
  const promise = await getData(ERROR_PROJECT, url, dispatch, true).then((response: any) => {
    project = response.data.project;
    if (project?.contributors) {
      project.contributors = project.contributors?.filter((c : IContributor) => c.user?._id);
    }
  });

  dispatch({
    type: GET_PROJECT,
    payload: project,
  });
  return promise;
};

export const postProjectAction = async (dispatch: DispatchType, data: IProjectForm): Promise<any> => {
  const url = '/project';
  let project: IProject | null = null;
  dispatch({
    type: LOADING_PROJECT,
  });
  const promise = await postData(ERROR_PROJECT, url, dispatch, data, true).then((response: any) => {
    project = response.data.project;
  });
  dispatch({
    type: POST_PROJECT,
    payload: project,
  });
  return promise;
};

export const putProjectAction = async (dispatch: DispatchType, id: string, data: IProjectForm): Promise<any> => {
  const url = `/project/${id}`;
  dispatch({
    type: LOADING_PROJECT,
  });
  let project: IProject | null = null;
  const promise = await putData(PUT_PROJECT, ERROR_PROJECT, url, dispatch, data, true).then((response : any) => {
    project = response.project;
    if (project?.contributors) {
      project.contributors = project.contributors?.filter((c : IContributor) => c.user?._id);
    }
  });
  dispatch({
    type: PUT_PROJECT,
    payload: project,
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Le projet a été modifié',
    },
  });
  return promise;
};

export const deleteProjectAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/project/${id}`;
  let deleted : any = null;
  deleted = await deleteData(ERROR_PROJECT, url, dispatch, true);
  dispatch({
    type: DELETE_PROJECT,
    payload: deleted,
  });
  return deleted;
};

export const searchProject = (dispatch: DispatchType, value: string): void => {
  dispatch({
    type: SEARCH_PROJECT,
    payload: value,
  });
};

export const getUsersAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/user';
  let users: IUser[] | null = null;
  const promise = await getData(ERROR_PROJECT, url, dispatch, true).then((response: any) => {
    users = response.data.users;
  });
  dispatch({
    type: GET_USERS,
    payload: users,
  });
  return promise;
};

export const postInviteUserAction = async (dispatch: DispatchType, data: IInviteForm): Promise<any> => {
  const url = '/user/invite';
  let invite: string | null = null;
  dispatch({
    type: LOADING_PROJECT,
  });
  const promise = await postData(ERROR_PROJECT, url, dispatch, data, true).then((response: any) => {
    invite = response.data.message;
  });
  if (invite) {
    dispatch({
      type: INVIT_USER,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: "L'utilisateur à été invité sur ce projet",
      },
    });
  }
  return promise;
};
