/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getData, postData, putData,
} from './index';
import { DispatchType } from '../reducers';
import {
  TEMPLATE_ITEM_GET,
  TEMPLATE_ITEM_LOADING,
  TEMPLATE_ITEM_ERROR,
  TEMPLATE_ITEM_POST,
  TEMPLATE_ITEM_PUT,
} from './actions';

export const getTemplateItemsAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/template-items';
  let templateItems;
  dispatch({
    type: TEMPLATE_ITEM_LOADING,
  });

  const promise = await getData(TEMPLATE_ITEM_ERROR, url, dispatch, true).then((response: any) => {
    templateItems = response.data.templateItems;
  });
  dispatch({
    type: TEMPLATE_ITEM_GET,
    payload: templateItems,
  });
  return promise;
};

export const postTemplateItemsAction = async (dispatch: DispatchType, data: FormData): Promise<any> => {
  const url = '/template-items';
  let templateItem;
  dispatch({
    type: TEMPLATE_ITEM_LOADING,
  });
  const promise = await postData(TEMPLATE_ITEM_ERROR, url, dispatch, data, true).then((response: any) => {
    templateItem = response.data.templateItem;
  });
  dispatch({
    type: TEMPLATE_ITEM_POST,
    payload: templateItem,
  });
  return promise;
};

export const putTemplateItemAction = async (dispatch: DispatchType, id: string, data: FormData): Promise<any> => {
  const url = `/template-items/${id}`;
  let templateItem;
  dispatch({
    type: TEMPLATE_ITEM_LOADING,
  });
  const promise = await await putData(TEMPLATE_ITEM_PUT, TEMPLATE_ITEM_ERROR, url, dispatch, data, true).then((response: any) => {
    templateItem = response.templateItem;
  });
  dispatch({
    type: TEMPLATE_ITEM_PUT,
    payload: templateItem,
  });
  return promise;
};
