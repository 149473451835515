import React, { useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import { FieldValues, useForm, useWatch } from 'react-hook-form';

import { SET_GROUP_MODALE, SET_ITEM_MODALE } from '../../actions/actions';
import { deleteGroupAction, putGroupAction } from '../../actions/group';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import {
  ErrorField, InputText, Textarea,
} from '../../lib/HooksFormFields';
import styles from './GroupModale.module.scss';
import ColorChart from '../ColorChart/ColorChart';
import { Timer } from '../ProjectHeader';
import { IContributor } from '../../types/projects.types';
import Comments from '../Comments/Comments';
import { getSceneAction } from '../../actions/scene';
import { IItem } from '../../types/scene.types';

export default function GroupModale({ updateItem }: { updateItem: any }) {
  const dispatch = useAppDispatch();
  const timerRef = useRef<Timer | null>();
  const { sceneReducer, authReducer } = useAppSelector((store) => store);
  const { project } = useAppSelector((state: any) => state.projectsReducer);
  const { user } = authReducer;
  const isMember = user?._id !== project?.owner._id && project?.contributors?.find((c :IContributor) => c.user._id === user?._id)?.role === 'Member';
  const { group } = sceneReducer.modale;
  const { activeTool, scene } = sceneReducer;

  const {
    watch,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    handleSubmit,
  } = useForm<FieldValues>();

  const watchData = useWatch({
    control,
  });

  const [comment, setComment] = useState('');

  const requiredRule = { required: 'Ce champs est requis' };
  const form = watch();

  async function onSubmit() {
    const data : any = { ...form };
    if (group?._id) {
      await putGroupAction(dispatch, group?._id, data);
      const res = await getSceneAction(dispatch, scene?._id || '');
      if (res.data.scene?.items) {
        res.data.scene?.items.forEach((it: IItem) => {
          updateItem(it, false);
        });
      }
    }
    return null;
  }
  function close() {
    dispatch({
      type: SET_ITEM_MODALE,
      payload: null,
    });
  }

  async function handleDelete() {
    if (group?._id) {
      await deleteGroupAction(dispatch, group._id);
      const res = await getSceneAction(dispatch, scene?._id || '');
      if (res.data.scene?.items) {
        res.data.scene?.items.forEach((it: IItem) => {
          updateItem(it, false);
        });
      }
      dispatch({
        type: SET_GROUP_MODALE,
        payload: null,
      });
    }
  }

  function handleSave() {
    timerRef.current = setTimeout(() => {
      handleSubmit(onSubmit)();
    }, 1000);
  }

  useEffect(() => {
    if (group?._id) {
      reset(group);
    }
  }, [group]);

  useEffect(() => {
    if (isDirty) {
      if (timerRef?.current) {
        clearTimeout(timerRef.current);
      }
      handleSave();
    }
  }, [watchData, isDirty]);

  async function submitComment() {
    if (group?._id && user?._id) {
      const commentData = {
        comment,
        author: user._id,
        date: new Date().toISOString(),
      };
      const comments = group.comments || [];
      await putGroupAction(dispatch, group._id, { ...group, comments: [commentData, ...comments] });
    }
    return setComment('');
  }

  return (
    <div className={`${styles.container} ${group ? styles.active : ''} ${isMember ? styles.member : ''}`}>
      <button
        type="button"
        className={`${styles.close} ${isDirty}`}
        onClick={() => close()}
      >
        <MdClose />
      </button>
      <div className={styles.content}>
        {activeTool !== 'comment'
          ? (
            <>
              <div className={styles.form}>
                <div className={styles['container-field']}>
                  <InputText
                    name="title"
                    control={control}
                    rules={requiredRule}
                    label="Nom"
                    placeholder="Nom..."
                  />
                  {errors?.name && <ErrorField message={errors.name.message} />}
                </div>
                <div className={styles['container-field']}>
                  <Textarea
                    name="description"
                    control={control}
                    rules={{ }}
                    label="Description"
                    placeholder="Description..."
                  />
                  {errors?.description && <ErrorField message={errors.description.message} />}
                </div>
                <ColorChart
                  name="color"
                  value={watch('color')}
                  setValue={(value: string) => setValue('color', value, {
                    shouldDirty: true,
                  })}
                  rules={requiredRule}
                  control={control}
                  error={errors?.color?.message}
                />
              </div>
              {group?._id
              && (
              <div className={styles.submit}>
                <button
                  type="button"
                  onClick={() => handleDelete()}
                >
                  <AiOutlineDelete />
                </button>
              </div>
              )}
            </>
          )
          : (
            <>
              <div className={styles.infos}>
                <div className={styles.title}>
                  <h3>{group?.title}</h3>
                </div>
                {group?.description && <p>{group?.description}</p>}
              </div>
              <div className={styles.comment}>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Ajoute un commentaire..."
                />
                {comment
                  && (
                  <button
                    type="button"
                    onClick={() => submitComment()}
                  >
                    <span>Valider</span>
                  </button>
                  )}
                {group?.comments
                  && (
                  <p>
                    {group?.comments.length}
                    {' '}
                    Commentaire
                    {group?.comments.length !== 1 && 's'}
                  </p>
                  )}
              </div>
              <Comments
                comments={group?.comments}
              />
            </>
          )}
      </div>
    </div>
  );
}
