/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { putSceneAction } from '../../actions/scene';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { ErrorField, InputColor } from '../../lib/HooksFormFields';
import styles from './ColorChart.module.scss';

function Color({ color, setValue, deleteColor } : { color: string, setValue: any, deleteColor: any }) {
  return (
    <div className={styles.color}>
      <button
        type="button"
        className={styles.value}
        onClick={() => setValue(color)}
        style={{ backgroundColor: color }}
      />
      <button
        type="button"
        onClick={() => deleteColor()}
        className={styles.delete}
      >
        <AiOutlineDelete color={color} />
      </button>
    </div>
  );
}

export default function ColorChart(
  {
    name,
    value = '',
    setValue,
    control,
    rules = {},
    error,
  }
  :{
    name: string,
    value: string | null,
    setValue: any,
    control: any,
    rules: any
    error?: string | null | any,
  },
) {
  const dispatch = useAppDispatch();
  const { scene } = useAppSelector((state) => state.sceneReducer);
  const colorChart = scene?.colorChart || [];
  function addToColorChart(color : string) {
    if (scene?._id) {
      putSceneAction(dispatch, scene?._id, { colorChart: [...colorChart, color] });
    }
  }

  function deleteFromColorChart(color : string) {
    if (scene?._id) {
      putSceneAction(dispatch, scene?._id, { colorChart: colorChart.filter((c) => c !== color) });
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <InputColor
          name={name}
          control={control}
          rules={rules}
          label="Couleurs"
          inline
          isInput
          placeholder="Description..."
        />
        {value
        && (
        <button
          type="button"
          onClick={() => addToColorChart(value)}
          className={`${styles.btn} ${colorChart.find((c) => c === value) ? styles.disabled : ''} ${styles['primary-icon']}`}
        >
          <BiPlus />
        </button>
        )}
      </div>
      {colorChart.length > 0
      && (
      <div className={styles.colorChart}>
        {colorChart?.map((c : string) => (
          <Color
            key={c}
            color={c}
            setValue={setValue}
            deleteColor={() => deleteFromColorChart(c)}
          />
        ))}
      </div>
      )}
      {error && <ErrorField message={error} />}
    </div>
  );
}
