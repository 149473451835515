import React, { useEffect, useRef } from 'react';
import { IoReload } from 'react-icons/io5';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import { deleteFileAction, postFileAction } from '../../actions/file';
import styles from './InputFile.module.scss';
import { POST_FILE } from '../../actions/actions';

function InputFile({
  id = 'file',
  label = 'Importer le fichier',
  className = '',
  activeFile,
  handleChange,
} : {
  id: string,
  label?: string,
  className?: string,
  activeFile: string,
  // eslint-disable-next-line no-unused-vars
  handleChange: (val: string | null) => void,
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch: DispatchType = useAppDispatch();
  const { file, isLoading, error } = useAppSelector((state: any) => state.fileReducer);

  function handleChangeFile(fileData : FileList | null) {
    const formData = new FormData();
    if (fileData && fileData[0]) {
      if (activeFile) {
        handleChange(null);
        deleteFileAction(dispatch, activeFile);
      }
      formData.append('file', fileData[0]);
      formData.append('title', fileData[0].name.split('.')[0]);
      formData.append('description', fileData[0].name.split('.')[0]);
      formData.append('alt', fileData[0].name.split('.')[0]);
      console.log(fileData, activeFile);
      postFileAction(dispatch, formData);
    }
  }

  useEffect(() => {
    if (file?._id) {
      if (file?._id) {
        let inputValue = (document.getElementById(id) as HTMLInputElement).value;
        if (inputValue) {
          inputValue = '';
        }
      }
      dispatch({
        type: POST_FILE,
        payload: null,
      });
      handleChange(file?._id);
    }
  }, [file]);
  console.log(error);
  return (
    <div className={styles.container}>
      {isLoading
        ? (
          <label className={`${styles.btn} ${styles.primary} ${className ? styles[className] : ''}`}>
            Traitement en cours
            <div className={styles.loader}><IoReload size={20} /></div>
          </label>
        ) : (
          <label htmlFor={id} className={`${styles.btn} ${styles.primary} ${styles.primary} ${className ? styles[className] : ''}`}>
            {label}
            <IoReload size={20} />
          </label>
        )}
      <input ref={inputRef} type="file" onChange={(e) => handleChangeFile(e.target.files)} id={id} />
    </div>
  );
}

export default InputFile;
