/* eslint-disable no-unused-vars */
import { any } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaComments } from 'react-icons/fa';
import { VscAdd } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom';
import { deleteSceneAction, postSceneAction, putSceneAction } from '../../actions/scene';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import { IContributor } from '../../types/projects.types';
import { IItem, ILink, IScene } from '../../types/scene.types';
import { Timer } from '../ProjectHeader';
import styles from './SceneAside.module.scss';

function Icon({ type, color = '#84899A' }: { type: string, color?: string }) {
  if (type === 'board') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="9"
        fill="none"
        style={{ transform: 'scale(1.2)', margin: 4 }}
        viewBox="0 0 16 9"
      >
        <path
          stroke={color}
          strokeWidth="1.2"
          d="M7.427.688l7.35 4.132-6.204 3.492-7.35-4.132L7.428.688z"
        />
      </svg>
    );
  }
  if (type === 'object') {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" style={{ transform: 'scale(1.1)', margin: '4px 6px' }} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.16275 0.911096C7.05829 0.86903 6.94171 0.86903 6.83725 0.911096L1.61525 3.0098L7 5.17357L12.3848 3.0098L7.16275 0.911096ZM13.125 3.65955L7.4375 5.94553V12.9107L13.125 10.6248V3.65955ZM6.5625 12.9116V5.94465L0.875 3.65955V10.6256L6.5625 12.9116ZM6.51262 0.0942973C6.8255 -0.0314324 7.1745 -0.0314324 7.48738 0.0942973L13.7252 2.60184C13.8064 2.6345 13.8759 2.69081 13.9248 2.76352C13.9738 2.83623 14 2.92201 14 3.0098V10.6256C13.9999 10.8013 13.9474 10.973 13.8493 11.1184C13.7512 11.2638 13.612 11.3764 13.4496 11.4416L7.16275 13.9684C7.05829 14.0105 6.94171 14.0105 6.83725 13.9684L0.55125 11.4416C0.38872 11.3765 0.249325 11.264 0.151062 11.1186C0.052799 10.9731 0.000180538 10.8014 0 10.6256L0 3.0098C2.0335e-05 2.92201 0.0262008 2.83623 0.0751686 2.76352C0.124136 2.69081 0.193649 2.6345 0.27475 2.60184L6.51262 0.0942973Z"
          fill={color}
        />
      </svg>
    );
  }
  if (type === 'link') {
    return (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: 6 }}
      >
        <path d="M2 2L11 11" stroke={color} strokeWidth="1.2" />
        <circle cx="2" cy="2" r="1.5" fill={color} stroke={color} />
        <circle cx="11" cy="11" r="1.5" fill={color} stroke={color} />
      </svg>
    );
  }
  if (type === 'actor') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{ fill: color, transform: 'scale(0.8)' }}
      >
        <path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z" />

      </svg>
    );
  }
  return null;
}

export function Item({
  item,
  isGroup = false,
  setItemModale,
  setLinkModale,
}: {
  item : IItem,
  isGroup?: boolean,
  setItemModale: (itemId: string) => void;
  setLinkModale: (arg: any) => void;
}) {
  const { itemsList, modale, activeTool } = useAppSelector((state: any) => state.sceneReducer);
  const isActive = modale.item?._id === item._id;

  return (
    <>
      <div className={`${styles.item} ${isGroup ? styles.isGroup : ''} ${isActive ? styles.active : ''}`} onClick={() => setItemModale(item._id)}>
        <div className={styles.color} style={{ backgroundColor: item.color }} />
        <div className={styles.label}>
          <Icon type={item?.templateItem?.category} color={isActive ? '#FFFFFF' : '#84899A'} />
          <p>{item.title}</p>
        </div>
        {activeTool === 'comment' && item?.comments && item?.comments?.length > 0
          && (
          <div className={styles.comments}>
            {item.comments?.length}
            <FaComments />
          </div>
          )}
      </div>
      {item?.links.map((link : ILink) => (
        <div
          key={link._id}
          className={`${styles.item} ${isGroup ? styles.isLinkGroup : ''} ${modale.link?.linkData?._id === link._id ? styles.active : ''}`}
          onClick={() => setLinkModale({
            linkData: link,
            source: item._id,
            sourceSlot: item.slot,
            target: itemsList.find((i :IItem) => i._id === link.target)._id,
            targetSlot: itemsList.find((i :IItem) => i._id === link.target).slot,
          })}
        >
          <div className={styles.color} style={{ backgroundColor: item.color }} />
          <div className={styles.label}>
            <Icon type="link" color={modale.link?.linkData?._id === link._id ? '#FFFFFF' : '#84899A'} />
            <p>{link.title}</p>
          </div>
          {activeTool === 'comment' && link.comments && link.comments?.length > 0
          && (
          <div className={styles.comments}>
            {link.comments?.length}
            <FaComments />
          </div>
          )}
        </div>
      ))}
    </>
  );
}

function SceneAside({
  setItemModale,
  setLinkModale,
}: {
  setItemModale: () => void,
  setLinkModale: () => void,
}) {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const { scene, itemsList, isLoading } = useAppSelector((state: any) => state.sceneReducer);
  const { project } = useAppSelector((state: any) => state.projectsReducer);
  const { user } = useAppSelector((state: any) => state.authReducer);
  const isMember = user._id !== project?.owner?._id && project?.contributors?.find((c :IContributor) => c.user._id === user._id)?.role === 'Member';
  const [title, setTitle] = useState('');
  const timerRef = useRef<Timer | null>();
  const scenesLinks : IScene[] = project?.scenes?.filter((s: IScene) => s._id !== scene?._id);

  function handleChangeTitle() {
    timerRef.current = setTimeout(() => {
      putSceneAction(dispatch, scene._id, { ...scene, title });
    }, 2000);
  }

  async function addScene() {
    const res = await postSceneAction(dispatch, { title: 'Nouvelle scène', project: project._id });
    if (res?.data?.scene?._id) {
      navigate(`/project/${project._id}/${res?.data?.scene?._id}`);
    }
  }

  async function deleteScene() {
    const res = await deleteSceneAction(dispatch, scene._id);
    if (res && scenesLinks.length > 0) {
      navigate(`/project/${project._id}/${scenesLinks[0]._id}`);
    }
    if (res && scenesLinks.length === 0) {
      navigate(`/project/${project._id}`);
    }
  }

  useEffect(() => {
    if (scene?.title) {
      setTitle(scene?.title);
    }
  }, [scene?.title]);

  useEffect(() => {
    if (project?._id && !isLoading && title && scene?.title !== title) {
      if (timerRef?.current) {
        clearTimeout(timerRef.current);
      }
      handleChangeTitle();
    }
  }, [title]);

  return (
    <div className={styles.container}>
      <div className={styles.scenes}>
        {scenesLinks?.map((s: IScene) => (
          <Link key={s._id} to={`/project/${project._id}/${s._id}`}>
            {s.title}
          </Link>
        ))}
      </div>
      <div className={styles.title}>
        {!isMember
          ? (
            <input
              type="text"
              placeholder="Nom de la scène"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          )
          : (
            <p>{scene?.title}</p>
          )}
        {!isMember
          && (
          <button
            className={`${styles.btn} ${styles['primary-icon']}`}
            onClick={() => addScene()}
          >
            <VscAdd />
          </button>
          )}
      </div>
      <div className={styles.list}>
        {itemsList?.map((item : IItem) => (
          <Item
            key={item._id}
            item={item}
            setItemModale={setItemModale}
            setLinkModale={setLinkModale}
          />
        ))}
        {!isMember && scenesLinks?.length > 0
          && (
          <button
            className={`${styles.btn}`}
            onClick={() => deleteScene()}
          >
            <AiOutlineDelete />
            <p>Supprimer la scène</p>
          </button>
          )}
      </div>
    </div>
  );
}

export default SceneAside;
