/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getData, putData, postData, deleteData,
} from './index';
import { DispatchType } from '../reducers';
import {
  LOADING_ITEM,
  ERROR_ITEM,
  GET_SCENE,
  PUT_SCENE,
  POST_ITEM,
  PUT_ITEM,
  DELETE_ITEM,
  POST_SCENE,
  DELETE_SCENE,
  ERROR_SCENE,
  RESET_SCENE,
  // DELETE_ITEM,
} from './actions';
import {
  IItem, IUpdatedScene, IUpdateItem,
} from '../types/scene.types';

export const getSceneAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/scene/${id}`;
  dispatch({
    type: RESET_SCENE,
  });
  const promise = await getData(ERROR_ITEM, url, dispatch, true);
  if (promise.data.scene) {
    dispatch({
      type: GET_SCENE,
      payload: promise.data.scene,
    });
  }
  return promise;
};

export const postSceneAction = async (dispatch: DispatchType, data: { title: string; project: string; }): Promise<any> => {
  const url = '/scene';
  let scene;
  const promise = await postData(ERROR_ITEM, url, dispatch, data, true);
  if (promise.data.scene) {
    scene = promise.data.scene;
    dispatch({
      type: POST_SCENE,
      payload: scene,
    });
  }
  return promise;
};

export const deleteSceneAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/scene/${id}`;

  const promise = await deleteData(ERROR_SCENE, url, dispatch, true);
  if (promise.message) {
    dispatch({
      type: DELETE_SCENE,
      payload: id,
    });
  }
  return promise;
};

export const putSceneAction = async (dispatch: DispatchType, id: string, data: IUpdatedScene): Promise<any> => {
  const url = `/scene/${id}`;
  let scene;
  const promise : any = await putData(PUT_SCENE, ERROR_ITEM, url, dispatch, data, true).then((response: any) => {
    scene = response.scene;
  });
  dispatch({
    type: PUT_SCENE,
    payload: scene,
  });
  return promise;
};

export const postItemAction = async (dispatch: DispatchType, data: IUpdateItem): Promise<any> => {
  const url = '/item';
  let item: IItem | null = null;
  dispatch({
    type: LOADING_ITEM,
  });
  const promise = await postData(ERROR_ITEM, url, dispatch, data, true);
  if (promise.data.item) {
    item = promise.data.item;
    dispatch({
      type: POST_ITEM,
      payload: item,
    });
  }
  return promise;
};

export const putItemAction = async (dispatch: DispatchType, id: string, data: IUpdateItem): Promise<any> => {
  const url = `/item/${id}`;
  let item: IItem | null = null;
  const promise : any = await putData(PUT_ITEM, ERROR_ITEM, url, dispatch, data, true);
  if (promise.item) {
    item = promise.item;
    dispatch({
      type: PUT_ITEM,
      payload: item,
    });
  }
  return promise;
};

export const deleteItemAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/item/${id}`;

  const promise = await deleteData(ERROR_ITEM, url, dispatch, true);
  if (promise.message) {
    dispatch({
      type: DELETE_ITEM,
      payload: id,
    });
  }
  return id;
};
