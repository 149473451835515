import React from 'react';
import styles from './Mobile.module.scss';

export default function Mobile(): JSX.Element {
  return (
    <div className={styles.container}>
      <p>Bakalab n&apos;est pas disponible sur mobile</p>
    </div>
  );
}
