const style = {
  container: (provided) => ({ ...provided }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    backgroundColor: '#12141A',
    '&:hover': {
      borderColor: state.isFocused ? '#FF8469' : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px', color: '#84899A' }),
  multiValue: (provided) => ({ ...provided, fontSize: '14px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#12141A',
    border: '1px solid #84899A',
    zIndex: 301,
  }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided, color: '#84899A' }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? 'white' : '#84899A',
    backgroundColor: state.isSelected ? '#FF8469' : '',
    '&:hover': {
      backgroundColor: '#2e2f36',
      color: '#84899A',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided, color: '#84899A' }),
};

export default style;
