import { Action } from '../types';
import {
  ERROR_ITEM,
  GET_SCENE,
  LOADING_ITEM,
  POST_ITEM,
  DELETE_ITEM,
  PUT_ITEM,
  SET_ACTIVE_TOOL,
  SET_ITEM_MODALE,
  SET_LINK_MODALE,
  PUT_SCENE,
  GET_PROJECT_GROUPS,
  SET_GROUP_MODALE,
  PUT_GROUP,
  DELETE_GROUP,
  POST_GROUP,
  GET_SCENE_ITEMS,
  RESET_SCENE,
} from '../actions/actions';
import { SceneState } from '../types/scene.types';

const initialState: SceneState = {
  scene: null,
  item: null,
  itemsList: [],
  groups: [],
  error: null,
  activeTool: 'select',
  changeAt: new Date().toISOString(),
  modale: {
    item: null,
    link: null,
    group: null,
  },
  isLoading: false,
};

const sceneReducer = (
  state: SceneState = initialState,
  action: Action,
): SceneState => {
  let updatedState = { ...state };
  const itemsList = [...state.itemsList] || [];
  const itemIndex = itemsList.findIndex((p) => p._id === action.payload?._id);
  const groupsList = [...state.groups] || [];
  const groupIndex = groupsList.findIndex((p) => p._id === action.payload?._id);
  switch (action.type) {
    case GET_PROJECT_GROUPS:
      updatedState = {
        ...state,
        isLoading: false,
        groups: action.payload,
      };
      break;
    case POST_GROUP:
      if (groupIndex !== -1) {
        groupsList[groupIndex] = { ...action?.payload };
      }
      updatedState = {
        ...state,
        groups: [...groupsList, action.payload],
        modale: { item: null, link: null, group: action.payload },
      };
      break;
    case PUT_GROUP:
      if (groupIndex !== -1) {
        groupsList[groupIndex] = { ...action?.payload };
      }
      updatedState = {
        ...state,
        groups: groupsList,
        modale: { item: null, link: null, group: action.payload },
      };
      break;
    case DELETE_GROUP:
      updatedState = {
        ...state,
        groups: groupsList.filter((p) => p._id !== action.payload),
      };
      break;
    case GET_SCENE_ITEMS:
      updatedState = {
        ...state,
        itemsList: action.payload,
      };
      break;
    case RESET_SCENE:
      updatedState = {
        ...state,
        scene: null,
        itemsList: [],
        item: null,
        isLoading: false,
        error: null,
      };
      break;
    case GET_SCENE:
      updatedState = {
        ...state,
        scene: {
          title: action.payload.title,
          _id: action.payload._id,
          colorChart: action.payload.colorChart,
        },
        itemsList: action.payload.items,
        item: null,
        isLoading: false,
        error: null,
      };
      break;
    case PUT_SCENE:
      updatedState = {
        ...state,
        scene: {
          title: action.payload.title,
          _id: action.payload._id,
          colorChart: action.payload.colorChart,
        },
        error: null,
      };
      break;
    case DELETE_ITEM:
      updatedState = {
        ...state,
        isLoading: false,
        itemsList: itemsList.filter((p) => p._id !== action.payload),
        error: null,
      };
      break;
    case PUT_ITEM:
      if (itemIndex !== -1) {
        itemsList[itemIndex] = { ...action?.payload };
      }
      updatedState = {
        ...state,
        isLoading: false,
        itemsList: [...itemsList],
        changeAt: new Date().toISOString(),
        error: null,
      };
      break;
    case POST_ITEM:
      updatedState = {
        ...state,
        isLoading: false,
        itemsList: [...itemsList, action.payload],
        changeAt: new Date().toISOString(),
        error: null,
      };
      break;
    case SET_LINK_MODALE:
      updatedState = { ...state, modale: { link: action.payload, item: null, group: null } };
      break;
    case SET_GROUP_MODALE:
      // eslint-disable-next-line no-case-declarations
      const group = action.payload ? state.groups.find((item) => item._id === action.payload) : null;
      updatedState = { ...state, modale: { item: null, link: null, group } };
      break;
    case SET_ITEM_MODALE:
      // eslint-disable-next-line no-case-declarations
      const found = action.payload ? state.itemsList.find((item) => item._id === action.payload) : null;
      updatedState = { ...state, modale: { item: found, link: null, group: null } };
      break;
    case SET_ACTIVE_TOOL:
      updatedState = { ...state, activeTool: action.payload || null };
      break;
    case LOADING_ITEM:
      updatedState = { ...state, isLoading: false };
      break;
    case ERROR_ITEM:
      updatedState = { ...state, error: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default sceneReducer;
