import React from 'react';
import { format } from 'date-fns';
import { GoKebabVertical } from 'react-icons/go';
import { NavLink, useNavigate } from 'react-router-dom';
import { IContributor, IProject } from '../../types/projects.types';
import styles from './ProjectCard.module.scss';
import { useAppSelector } from '../../hooks/useRedux';

function ProjectCard({ project }:{ project: IProject }) {
  const { user } = useAppSelector((state: any) => state.authReducer);
  const isMember = user?._id !== project?.owner?._id && project?.contributors.find((c :IContributor) => c.user._id === user?._id)?.role === 'Member';
  const navigate = useNavigate();
  const url = project.scenes.length > 0
    ? `/project/${project._id}/${project.scenes[0]?._id}`
    : `/project/${project._id}`;

  function editProject(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    navigate(`/home/project/${project._id}`);
  }

  return (
    <NavLink to={url} className={styles.container}>
      <div className={styles.image}>
        {project?.thumbnail && typeof project?.thumbnail !== 'string' && <img src={`${process.env.REACT_APP_API_URL}/${project?.thumbnail?.path}`} alt="default" />}
        {!isMember
        && (
          <button
            type="button"
            onClick={(e) => editProject(e)}
          >
            <GoKebabVertical />
          </button>
        )}
      </div>
      <div className={styles.info}>
        <h4>{project.title}</h4>
        <p>
          Edité le
          {' '}
          {format(new Date(project.updatedAt), 'dd-MM-yyyy HH:mm')}
        </p>
      </div>
    </NavLink>
  );
}

export default ProjectCard;
