/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { AnyObject } from '../types';
import { DispatchType } from '../reducers';
import { AUTH_ERROR } from './actions';

const API_URL = process.env.REACT_APP_API_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorHandler(dispatch: DispatchType, error: any, type: string): void {
  console.log('Error type: ', type);
  console.log(error);

  if (type !== AUTH_ERROR && error.response !== undefined && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }

    if (localStorage.getItem('id')) {
      localStorage.removeItem('id');
    }
    window.location.href = '/';
  }
  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

// export async function postData(errorType: string, url: string, dispatch: DispatchType, data: any, isAuthReq: boolean): Promise<any> {
//   const requestUrl = API_URL + url;
//   const config = { headers: {} };
//   let res = null;
//   let error = null;
//   try {
//     if (isAuthReq) {
//       const token = localStorage.getItem('token');
//       config.headers = { Authorization: `${token}` };
//     }
//     res = await axios.post(requestUrl, data, config);
//   } catch (err) {
//     error = err;
//     errorHandler(dispatch, err, errorType);
//   }
//   return res?.status === 200 || res?.status === 201 ? res : error;
// }

export function postData(errorType: string, url: string, dispatch: DispatchType, data: any, isAuthReq: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.post(requestUrl, data, config)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function getData(errorType: string, url: string, dispatch: DispatchType, isAuthReq: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.get(requestUrl, config)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function putData(action: string, errorType: string, url: string, dispatch: DispatchType, data: AnyObject, isAuthReq = true) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }
    axios.put(requestUrl, data, config)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        reject(action);
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function deleteData(errorType: string, url: string, dispatch: DispatchType, isAuthReq: boolean): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.delete(requestUrl, config)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}
