import { postData, getData, putData } from './index';
import { DispatchType } from '../reducers';
import {
  AUTH_SIGNIN,
  AUTH_GET_PROFILE,
  AUTH_PUT_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
  SET_TOAST,
} from './actions';
import {
  IUser,
  IProfileForm,
  IProfile,
  ISignIn,
  IForgotPassword,
  IResetPassword,
  IRegisterFormValues,
} from '../types/auth.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signIn = async (dispatch: DispatchType, data: ISignIn): Promise<any> => {
  const url = '/auth/login';
  let user: IUser | null = null;
  let token = null;
  dispatch({
    type: AUTH_LOADING,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    user = response.data.user;
    token = response.data.token;
  });
  dispatch({
    type: AUTH_SIGNIN,
    payload: { user, token },
  });
  return promise;
};

export const register = async (dispatch: DispatchType, data: IRegisterFormValues): Promise<any> => {
  const url = '/user/register';
  dispatch({
    type: AUTH_LOADING,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false);
  if (promise?.data?.user) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'Votre compte à été créé',
      },
    });
  }
  return promise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const forgotPassword = async (dispatch: DispatchType, data: IForgotPassword): Promise<any> => {
  const url = '/auth/forgot-password';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    console.log(response);
  });
  return promise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resetPassword = async (dispatch: DispatchType, data: IResetPassword): Promise<any> => {
  const url = `/auth/reset-password/${data.token}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false).then((response: any) => {
    console.log(response);
  });
  return promise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProfileAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/profile';
  let user = {};
  dispatch({
    type: AUTH_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await getData(AUTH_ERROR, url, dispatch, true).then((response: any) => {
    user = response.data.user;
  });

  dispatch({
    type: AUTH_GET_PROFILE,
    payload: user,
  });
  return promise;
};

export const putProfileAction = async (dispatch: DispatchType, data: IProfileForm): Promise<any> => {
  const url = '/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  let profile: IProfile | null = null;
  const promise = await putData(AUTH_PUT_PROFILE, AUTH_ERROR, url, dispatch, data, true).then((response : any) => {
    profile = response.user;
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'le profil a été modifé',
    },
  });
  dispatch({
    type: AUTH_PUT_PROFILE,
    payload: profile,
  });
  return promise;
};

export const resetError = (dispatch: DispatchType): void => {
  dispatch({
    type: AUTH_RESET_ERROR,
  });
};

export const logout = (dispatch: DispatchType): void => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};
