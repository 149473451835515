import { postData, deleteData } from './index';
import { DispatchType } from '../reducers';
import {
  POST_FILE,
  FILE_LOADING,
  FILE_ERROR,
} from './actions';

import { IFile } from '../types/file.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postFileAction = async (dispatch: DispatchType, data: FormData): Promise<any> => {
  const url = '/file';
  let file: IFile | null = null;
  dispatch({
    type: FILE_LOADING,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(FILE_ERROR, url, dispatch, data, true);
  if (promise.data.files.length > 0) {
    file = promise.data.files[0]?._id;
    dispatch({
      type: POST_FILE,
      payload: { _id: file },
    });
  }
  return file;
};

export const deleteFileAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/file/${id}`;

  const promise = await deleteData(FILE_ERROR, url, dispatch, true).then(() => {
    dispatch({
      type: FILE_ERROR,
      payload: id,
    });
  });
  return promise;
};
