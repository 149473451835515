import React, { useEffect } from 'react';
import { SET_ACTIVE_TOOL } from '../../actions/actions';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
// import ActorSvg from './icons/ActorSvg';
import ArrowSvg from './icons/ArrowSvg';
// import BoardSvg from './icons/BoardSvg';
import CommentSvg from './icons/CommentSvg';
import LinkSvg from './icons/LinkSvg';
import styles from './SceneTools.module.scss';

const tools = [
  'select',
  // 'board',
  // 'actor',
  'link',
  'comment',
];

// eslint-disable-next-line no-unused-vars
export default function SceneTools({ setActiveView } : { setActiveView: (tool: any) => void; }) {
  const { activeTool } = useAppSelector((state: any) => state.sceneReducer);
  const dispatch = useAppDispatch();
  const grey = '#84899A';
  const primary = '#FA5C4F';
  useEffect(() => {
    if (activeTool) {
      setActiveView(activeTool);
    }
  }, [activeTool]);
  return (
    <div className={styles.container}>
      {tools.map((t) => (
        <button
          key={t}
          type="button"
          className={`${styles.btn} ${activeTool === t ? styles.active : ''} ${styles['primary-icon']}`}
          onClick={() => dispatch({
            type: SET_ACTIVE_TOOL,
            payload: t,
          })}
        >
          {t === 'select' ? <ArrowSvg color={activeTool === t ? primary : grey} /> : ''}
          {/* {t === 'board' ? <BoardSvg color={activeTool === t ? primary : grey} /> : ''}
          {t === 'actor' ? <ActorSvg color={activeTool === t ? primary : grey} /> : ''} */}
          {t === 'link' ? <LinkSvg color={activeTool === t ? primary : grey} /> : ''}
          {t === 'comment' ? <CommentSvg color={activeTool === t ? primary : grey} /> : ''}
        </button>
      ))}
    </div>
  );
}
