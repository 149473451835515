/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { hexToRgba } from '../../helpers/utils';
import { useAppSelector } from '../../hooks/useRedux';
import styles from './SelectItem.module.scss';

export default function SelectItem(
  {
    label,
    value = '',
    setValue,
    category,
    color,
  }
  :{
    label: string,
    color: string,
    category: string,
    value: string | null,
    setValue: any,
  },
) {
  const { templateItemsReducer } = useAppSelector((store) => store);
  const { templateItems } = templateItemsReducer;
  return (
    <div className={styles.container}>
      <label>{label}</label>
      <ul className={styles.list}>
        {templateItems.filter((t) => t.category === category).map((item) => (
          <li key={`templateItem-${item._id}`}>
            <button
              className={styles.templateItem}
              onClick={() => setValue(item._id)}
              style={{
                border: item._id === value ? `1px solid ${color}` : `1px solid ${hexToRgba('#FFFFFF', 0.3)}`,
                backgroundColor: item._id === value ? hexToRgba(color, 0.5) : 'transparent',
              }}
            >
              {item.picture && (
              <img
                src={`${process.env.REACT_APP_API_URL}/${item.picture.path}`}
                alt={item.name}
              />
              )}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
