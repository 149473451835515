import React from 'react';
import { Controller } from 'react-hook-form';
import styles from './input-email.module.scss';

interface IInputEmail {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  required?: boolean,
  label?: string,
  inline?: boolean,
  widthLabel?: string | null,
  placeholder?: string,
  defaultValue?: string,
  className?: string,
}

function InputEmail({
  name,
  control,
  required = false,
  widthLabel = null,
  inline = false,
  label = '',
  placeholder = '',
  defaultValue = '',
  className = '',
}: IInputEmail): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required ? 'Ce champs email est obligatoire' : false,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: 'Veuillez renseigner une adresse email valide',
        },
      }}
      render={({
        field: {
          // name,
          value = '',
          ref,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input-email']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input-email']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <input
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
            className={styles.input}
            autoComplete="off"
            type="email"
            placeholder={placeholder}
          />
        </div>
      )}
    />
  );
}

export default InputEmail;
