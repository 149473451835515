import React, { KeyboardEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  InputEmail,
  InputPassword,
  ErrorField,
} from '../../../lib/HooksFormFields';

import styles from '../AuthForm.module.scss';

interface ISignInForm {
  errorMessage?: string | null,
  // eslint-disable-next-line no-unused-vars
  submit(data: any): any,
}

type FormValues = {
  identifiant: string,
  email: string,
  password: string,
};

function SignInForm({
  errorMessage,
  submit,
}: ISignInForm) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  function onSubmit(data: FormValues) {
    submit(data);
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <form autoComplete="off" className={styles.container}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={`${styles['container-field']} ${watch('email') ? styles['has-value'] : ''}`}>
          <InputEmail
            name="email"
            control={control}
            required
            label="Mail"
            placeholder="Adresse email..."
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        <div className={`${styles['container-field']} ${watch('password') ? styles['has-value'] : ''}`}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Mot de passe"
            placeholder="Saissisez votre mot de passe..."
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
        {(!errors.email && !errors.password) && errorMessage
          && (
            <p className={styles['message-error']}>
              {errorMessage}
            </p>
          )}
      </div>
      <div className={styles.reset}>
        <NavLink to="/forgot">Mot de passe oublié ?</NavLink>
      </div>
      <div className={styles.btn}>
        <button
          className={styles.primary}
          onClick={handleSubmit(onSubmit)}
        >
          Me connecter
        </button>
      </div>
    </form>
  );
}

export default SignInForm;
