import React, { useRef, useState } from 'react';
import { IoReload } from 'react-icons/io5';
import styles from './InputFile.module.scss';

function InputFile({
  name,
  label = 'Importer le fichier',
  className = '',
  clearError,
  handleChange,
} : {
  name: string,
  label?: string,
  className?: string
  clearError?: () => void;
  // eslint-disable-next-line no-unused-vars
  handleChange: (val: File | null) => void,
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');

  function handleChangeFile(fileData : FileList | null) {
    if (clearError) clearError();
    if (fileData && fileData[0]) {
      handleChange(fileData[0]);
      setFileName(fileData[0].name);
    }
  }

  return (
    <div className={styles.container}>
      <label htmlFor={name} className={`${styles.btn} ${styles.primary} ${styles.primary} ${className ? styles[className] : ''}`}>
        {fileName || label}
        <IoReload size={20} />
      </label>
      <input ref={inputRef} type="file" onChange={(e) => handleChangeFile(e.target.files)} id={name} />
    </div>
  );
}

export default InputFile;
