import { Action } from '../types';
import {
  ERROR_PROJECT,
  GET_PROJECTS,
  GET_PROJECT,
  LOADING_PROJECT,
  POST_PROJECT,
  DELETE_PROJECT,
  GET_USERS,
  PUT_PROJECT,
  SEARCH_PROJECT,
  INVIT_USER,
  POST_SCENE,
} from '../actions/actions';
import { ProjectsState } from '../types/projects.types';

const initialState: ProjectsState = {
  project: null,
  projectsList: null,
  error: null,
  search: '',
  isLoading: false,
  users: null,
};

const projectsReducer = (
  state: ProjectsState = initialState,
  action: Action,
): ProjectsState => {
  let updatedState = { ...state };
  const projectsList = state.projectsList ? [...state.projectsList] : [];
  const projectIndex = projectsList.findIndex((p) => p._id === action.payload?._id);
  switch (action.type) {
    case GET_PROJECT:
      updatedState = {
        ...state,
        project: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case GET_PROJECTS:
      updatedState = {
        ...state,
        projectsList: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case DELETE_PROJECT:
      updatedState = {
        ...state,
        isLoading: false,
        projectsList: projectsList.filter((p) => p._id !== action.payload),
        error: null,
      };
      break;
    case PUT_PROJECT:
      if (projectIndex) {
        projectsList[projectIndex] = { ...action.payload };
      }
      updatedState = {
        ...state,
        isLoading: false,
        projectsList: [...projectsList],
        project: action.payload,
        error: null,
      };
      break;
    case POST_SCENE:
      if (state.project?.scenes) {
        updatedState = {
          ...state,
          isLoading: false,
          project: {
            ...state.project,
            scenes: [...state.project.scenes, action.payload],
          },

          search: '',
          error: null,
        };
      }
      break;
    case POST_PROJECT:
      updatedState = {
        ...state,
        isLoading: false,
        projectsList: [...projectsList, action.payload],
        search: '',
        error: null,
      };
      break;
    case GET_USERS:
      updatedState = { ...state, users: action.payload };
      break;
    case INVIT_USER:
      updatedState = { ...state, isLoading: false };
      break;
    case LOADING_PROJECT:
      updatedState = { ...state, isLoading: true };
      break;
    case SEARCH_PROJECT:
      updatedState = { ...state, search: action.payload || '' };
      break;
    case ERROR_PROJECT:
      updatedState = { ...state, error: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default projectsReducer;
