import * as React from 'react';

function CommentSvg({ color }:{ color: string }) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1328_243)">
        <path d="M10.5 18.9545C15.7464 18.9545 20 15.0783 20 10.6721C20 6.26597 15.7464 3 10.5 3C5.25362 3 1 6.57167 1 10.9778C1 12.6976 1.64837 14.291 2.75156 15.5929L1.59375 21L6.244 18.1117C7.59152 18.6733 9.03885 18.9599 10.5 18.9545Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1328_243">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default CommentSvg;
