import React from 'react';
import { MdLogin } from 'react-icons/md';
import { useNavigate, NavLink } from 'react-router-dom';
import { logout } from '../../actions/auth';
import avatar from '../../assets/images/avatar.png';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { DispatchType } from '../../reducers';
import styles from './AuthProfile.module.scss';

function AuthProfile() {
  const navigate = useNavigate();
  const dispatch: DispatchType = useAppDispatch();
  const { user } = useAppSelector((state: any) => state.authReducer);

  function logoutUser() {
    logout(dispatch);
    navigate('/login');
  }

  return (
    <div className={`${styles.container}`}>
      <div
        className={styles.profile}
      >
        <NavLink to="/home/profile">
          <p>
            {user?.profile?.firstName}
            {' '}
            {user?.profile?.lastName}
          </p>
          <div className={styles.avatar}>
            <img src={user?.avatar?.path ? `${process.env.REACT_APP_API_URL}/${user?.avatar?.path}` : avatar} alt="bakalab-avatar" />
          </div>
        </NavLink>
        <button
          onClick={() => logoutUser()}
        >
          <MdLogin size={20} />
        </button>
      </div>
    </div>
  );
}

export default AuthProfile;
