import React, { useEffect, useState } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { postProjectAction } from '../../actions/projects';
import ProjectCard from '../../components/ProjectCard';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { Button } from '../../lib/HooksFormFields';
import { DispatchType } from '../../reducers';
import { IProject } from '../../types/projects.types';
import styles from './Projects.module.scss';

function Projects() {
  const dispatch: DispatchType = useAppDispatch();
  const { projectsList, isLoading, search } = useAppSelector((state: any) => state.projectsReducer);
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    if (projectsList) {
      let lists : IProject[] = projectsList ? [...projectsList] : [];
      lists = lists.filter((p: IProject) => {
        if (p.title.toLowerCase().includes(search.toLowerCase())) return p;
        return null;
      }).sort((a: IProject, b: IProject) => {
        const aDate = new Date(a.updatedAt).getTime();
        const bDate = new Date(b.updatedAt).getTime();
        return bDate - aDate;
      });
      setProjects(lists);
    }
  }, [projectsList, search]);

  return (
    <div className={styles.container}>
      <h2>Bienvenue sur Bakalab</h2>
      <div className={styles.title}>
        <h3>Derniers projets ouverts</h3>
        <Button
          type="button"
          className="primary"
          isLoading={isLoading}
          handleClick={() => postProjectAction(dispatch, { title: 'Nouveau projet', description: '' })}
        >
          <div className={styles.add}>
            Nouveau projet
            <HiOutlinePlusSm />
          </div>
        </Button>
      </div>
      <div className={styles.projects}>
        {projects?.map((p: IProject) => <ProjectCard key={p._id} project={p} />)}
      </div>
    </div>
  );
}

export default Projects;
