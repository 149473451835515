/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from './styled-select.module.scss';
import styleJs from './style';

function StyledSelect({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = '',
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => {
        console.log('controller focus');
      }}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <Select
            {...field}
            {...props}
            className={`${styles.select}`}
            placeholder={placeholder}
            styles={styleJs}
          />
        </div>
      )}
    />
  );
}

StyledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default StyledSelect;
