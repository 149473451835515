import React, {
  useRef,
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  createRef,
  useCallback,
} from 'react';

import { withWindowSize } from 'react-fns';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { ITemplateItem } from '../../types/templateItem.types';
import { SceneHandle } from '../../types/scene.types';
import runPixi from './runPixi';

import { putItemAction, postItemAction } from '../../actions/scene';
import { SET_GROUP_MODALE, SET_ITEM_MODALE, SET_LINK_MODALE } from '../../actions/actions';
import ItemModale from '../ItemModale/ItemModale';
import LinkModale from '../LinkModale/LinkModale';
import GroupModale from '../GroupModale/GroupModale';
// import { deleteFileAction, postFileAction } from '../../actions/file';
// import { putProjectAction } from '../../actions/projects';

interface IScene {
  width: number,
  height: number,
  componentRef: any,
}

const Scene = withWindowSize(({ width, height, componentRef }: IScene) => {
  const containerRef = createRef<HTMLDivElement>();
  const { sceneReducer, projectsReducer } = useAppSelector((store) => store);
  const { user } = useAppSelector((state: any) => state.authReducer);

  const { project } = projectsReducer;
  const { itemsList, scene, groups } = sceneReducer;

  const allowEdit = project?.owner?._id === user?._id || project?.contributors?.find((c) => c._id === user._id)?.role === 'Admin';

  const dispatch = useAppDispatch();
  const [init, setInit] = useState(false);
  const pixi: any = useRef();

  useImperativeHandle(componentRef, () => ({
    drawTile: (n: any, activeLabel: any) => pixi?.current?.drawTile(n, activeLabel),
    deleteTile: (id: string) => pixi.current.deleteTile(id),
    exportScene: () => pixi?.current?.exportScene(),
    deleteLink: (id: string) => pixi.current.deleteLink(id),
    setGroupModale: (id: string) => {
      dispatch({
        type: SET_GROUP_MODALE,
        payload: id,
      });
    },
    setItemModale: (id: string) => {
      dispatch({
        type: SET_ITEM_MODALE,
        payload: id,
      });
    },
    setLinkModale: (payload: any) => {
      dispatch({
        type: SET_LINK_MODALE,
        payload,
      });
    },
    drawLink: (sourceSlot: string, targetSlot: string, sourceId: string, targetId: string, link: any, activeLabel: boolean, origin?: string) => {
      pixi?.current?.drawLink(
        sourceSlot,
        targetSlot,
        sourceId,
        targetId,
        link,
        activeLabel,
        origin,
      );
    },
    setIsDraggable: (bool: boolean) => pixi?.current?.setIsDraggable(bool),
    setActiveView: (view: string) => {
      pixi?.current?.setActiveView(view);
    },
    dragThenAdd: (position: { x: number, y: number }, item: ITemplateItem) => pixi?.current?.dragThenAdd(position, item),
    hightLightClosest: (position: { x: number, y: number }) => pixi?.current?.hightLightClosest(position),
    size: {
      width,
      height,
    },
  }), [width, height]);

  const toggleModalItem = useCallback((id: string) => {
    dispatch({
      type: SET_ITEM_MODALE,
      payload: id,
    });
  }, []);

  useEffect(() => {
    if ((width || height) && !init && scene) {
      pixi.current = runPixi({
        allowEdit,
        container: containerRef.current,
        title: scene.title,
        updateItem: async (item: any) => {
          const data = { ...item };
          delete data._id;
          if (data.group?.value) {
            data.group = data.group?.value;
          }
          const res = await putItemAction(dispatch, item._id, data);
          const resItem = res?.item;
          return pixi.current.drawTile({ ...resItem });
        },
        toggleModalItem,
        setLinkModale: (payload: any) => {
          dispatch({
            type: SET_LINK_MODALE,
            payload,
          });
        },
        createItem: async (item: any) => {
          const newItem = {
            title: item.name,
            description: item.description,
            scene: scene?._id,
            slot: item.slot,
            color: item.color,
            templateItem: item._id,
          };
          const res = await postItemAction(dispatch, newItem);
          const resItem = res?.data?.item;
          return resItem;
        },
      });
      if (itemsList && itemsList.length > 0) {
        pixi.current.moveToCenter(itemsList);
        // pixi.current.updateGrid(itemsList);
        itemsList.forEach((it) => {
          const item : any = {
            ...it,
          };
          pixi.current.drawTile({ ...item }, false);
          item.links.forEach((link: any) => {
            const target = itemsList.find((i) => i._id === link.target);
            if (target) {
              pixi.current?.drawLink(item.slot, target.slot, item._id, target._id, link, false);
            }
          });
        });
      }
      setInit(true);
    }
  }, [
    init,
    itemsList,
    groups,
    width,
    height,
    scene,
  ]);

  useEffect(() => () => {
    if (pixi?.current && init) {
      setInit(false);
    }
  }, [scene?._id]);

  useEffect(() => () => {
    if (pixi?.current && init) {
      pixi.current.destroy();
    }
  }, [pixi?.current, init]);

  return scene
    ? (
      <>
        <div ref={containerRef} style={{ width, height }} />
        {pixi && (
          <ItemModale
            updateItem={(item : any, label: boolean) => {
              componentRef.current.drawTile({ ...item }, label);
            }}
            deleteItem={(id : string) => componentRef.current.deleteTile(id)}
          />
        )}
        {pixi && (
          <GroupModale
            updateItem={(item : any, label: boolean) => componentRef.current.drawTile({ ...item }, label)}
          />
        )}
        {pixi && (
          <LinkModale
            deleteLink={(id : string) => componentRef.current.deleteLink(id)}
            updateItem={(item : any, label: boolean) => componentRef.current.drawTile({ ...item }, label)}
            updateLink={(
              sourceSlot: string,
              targetSlot: string,
              sourceId: string,
              targetId: string,
              link: any,
              activeLabel: boolean,
            ) => componentRef.current.drawLink(
              sourceSlot,
              targetSlot,
              sourceId,
              targetId,
              link,
              activeLabel,
              'selection',
            )}
          />
        )}
      </>
    ) : null;
});
// eslint-disable-next-line react/jsx-props-no-spreading
export default forwardRef<SceneHandle | any>((props, ref) => <Scene width={0} height={0} componentRef={ref} />);
