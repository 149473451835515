/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { IoChevronDown } from 'react-icons/io5';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { VscAdd } from 'react-icons/vsc';
import { postGroupAction } from '../../actions/group';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { IGroup } from '../../types/group.types';
import { IItem } from '../../types/scene.types';
import { Item } from '../SceneAside';
import styles from './GroupAside.module.scss';
import { GET_SCENE_ITEMS } from '../../actions/actions';

function Group({
  group,
  setItemModale,
  setLinkModale,
  setGroupModale,
  updateItem,
}: {
  group: IGroup,
  setItemModale: () => void,
  setLinkModale: () => void,
  setGroupModale: (id: string) => void,
  updateItem: (item: IItem, bool: boolean) => void,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useAppDispatch();
  const { itemsList } = useAppSelector((state: any) => state.sceneReducer);
  const groupRef = useRef<HTMLDivElement|null>(null);
  const groupList = itemsList?.filter((a : any) => a.group?._id === group._id);
  const isVisible = itemsList?.find((a : any) => a.hidden);

  function hightLightGroup() {
    const updatedList = [...itemsList].map((i: any) => {
      const it = { ...i };
      console.log(isVisible, it?.group?._id);
      if (it?.group?._id === group?._id) {
        it.hidden = !it.hidden;
      }
      updateItem(it, false);
      return it;
    });
    dispatch({
      type: GET_SCENE_ITEMS,
      payload: updatedList,
    });
    console.log(updatedList);
  }

  return (
    <div className={styles.group}>
      <button
        type="button"
        className={`${styles.toggle} ${isOpen ? styles.open : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <IoChevronDown />
      </button>
      <div className={styles.content}>
        <div
          className={styles.header}
        >
          <div
            className={styles.title}
            onClick={() => setGroupModale(group._id || '')}
          >
            <div className={styles.puce} style={{ backgroundColor: group?.color }} />
            <p>{group.title}</p>
          </div>
          {itemsList?.find((a : any) => a?.group?._id === group?._id)
          && (
          <div
            className={styles.eye}
            onClick={() => hightLightGroup()}
          >
            {!groupList.find((g: any) => g.hidden) ? <AiOutlineEye color={group?.color} /> : <AiOutlineEyeInvisible />}
          </div>
          )}
        </div>
        <div
          className={styles.panel}
          style={{
            display: isOpen ? 'block' : 'none',
          }}
        >
          <div ref={groupRef} key={groupList.length}>
            {groupList
              ?.map((item : IItem) => (
                <Item
                  key={item._id}
                  item={item}
                  isGroup
                  setItemModale={setItemModale}
                  setLinkModale={setLinkModale}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function GroupAside({
  setItemModale,
  setLinkModale,
  setGroupModale,
  updateItem,
}: {
  setItemModale: () => void,
  setLinkModale: () => void,
  setGroupModale: (id: string) => void,
  updateItem: (item: IItem, bool: boolean) => void,
}) {
  const dispatch = useAppDispatch();
  const { groups } = useAppSelector((state: any) => state.sceneReducer);
  const { project } = useAppSelector((state: any) => state.projectsReducer);

  async function createGroup() {
    const res = await postGroupAction(dispatch, {
      title: 'Nouveau groupe',
      description: '',
      project: project._id,
      color: '#FA5C4F',
      comments: [],
    });
    setGroupModale(res.group?._id);
  }

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.add}
        onClick={() => createGroup()}
      >
        <VscAdd />
        <p>Ajouter un groupe</p>
      </button>
      <div className={styles.list}>
        {groups?.map((g : IGroup) => (
          <Group
            key={g._id}
            group={g}
            updateItem={updateItem}
            setItemModale={setItemModale}
            setLinkModale={setLinkModale}
            setGroupModale={setGroupModale}
          />
        ))}
      </div>
    </div>
  );
}

export default GroupAside;
